import { Component, OnInit   } from '@angular/core';

@Component({
  selector: 'app-installer-chat',
  templateUrl: './installer-chat.component.html',
  styleUrls: ['./installer-chat.component.css']
})
export class InstallerChatComponent implements OnInit {

  loginUser = JSON.parse(localStorage.getItem('installerUser'));
  constructor() { }

  ngOnInit() {
  }


}
