import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UserFormBuilderService {

  constructor(
    private formBuilder: FormBuilder
  ) { }

  // Sign up Form Builder installationDate
  addAppointmentForm () {
    return this.formBuilder.group({
      jobTitle: ['', [Validators.required]],
      installationDate: ['', [Validators.required]],
      userName: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      userAddress: ['', [Validators.required]],
      userCity: ['', [Validators.required]],
      userPhone: ['', [Validators.required, Validators.pattern("^[0-9]*$"),Validators.minLength(10),Validators.maxLength(10)]],
      precarious: ['', [Validators.required]],
      returned: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      rampant: ['', [Validators.required]],
      detuille: ['', [Validators.required]],
      roofHatch: ['', [Validators.required]],
      accessHeightCrawlSpace: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      requestInsulatorId: ['', [Validators.required]],
      insulatorSpreader: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      existingInsulation: ['', [Validators.required]],
      typeOfExistingInsulation: [''],
      sinceWhen: [''],
      roofHeight: [''],
      alreadyRefuse: ['', [Validators.required]],
      reasonForRefusal: [''],
      comments: ['', [Validators.required]],
      userTaxNumber: ['', [Validators.required]],
      referenceFisacle: ['', [Validators.required]],
      product1: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      product2: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      product3: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      product4: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      product5: ['', [Validators.required,Validators.pattern("^[0-9]*$")]]
    });
  }
}
