import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../_services/admin/admin.service';

@Component({
  selector: 'app-admin-job-list',
  templateUrl: './admin-job-list.component.html',
  styleUrls: ['./admin-job-list.component.css'],
  providers: [AdminService]
})
export class AdminJobListComponent implements OnInit {
  pageLoading = true;
  loginUser:any;
  items = [];
  pageOfItems: Array<any>;
  message: string;
  alertClass: string;
  alert:string;
  constructor(
    private router: Router,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.loginUser = JSON.parse (localStorage.getItem('appAdmin'));
    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
    this.pageLoading = true;
    this.adminService.jobList()
    .subscribe(
      res => {
        this.items = res['data'] || [];
        console.log("Response: ", this.items);
        if (res['success']) {
          this.alertClass = "alert-success";
          this.message = res['message'];
        } else {
          this.alert = "alert-danger";             
          this.message = res['message'];
        }
        this.pageLoading = false;
      },
      error => {
        console.log("Error: ", error);
        if (error.status === 401) {
          this.alert = "alert-danger";
          this.message = error.error['message'];
        }
        this.pageLoading = false;
      }
    );
}
onChangePage(pageOfItems: Array<any>) {
  // update current page of items
  this.pageOfItems = pageOfItems || [];
}
encode (str:any) {
  return btoa (str);
}

decode (str:any) {
  return atob (str);
}

}