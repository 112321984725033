import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-installer',
  templateUrl: './installer.component.html',
  styleUrls: ['./installer.component.css']
})
export class InstallerComponent implements OnInit {

  collapedSideBar: boolean;
    public userLang:any;
    public userLangFlag:any;

    menuToggle = false;

    constructor() {
    	    
    }

    onOpen (e) {
        this.menuToggle = false;
    }

    onClose (e) {
        this.menuToggle = true;
    }

    ngOnInit() {}

    receiveCollapsed($event) {
        this.collapedSideBar = $event;
    }
}
