import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeApiService } from '../../../_services/home/home-api.service';
import { AdminService } from '../../../_services/admin/admin.service';
import { Validators } from '@angular/forms';
import { HomeFormBuilderService } from 'src/app/_services/home/home-form-builder.service';

@Component({
  selector: 'app-edit-admin-commission',
  templateUrl: './edit-admin-commission.component.html',
  styleUrls: ['./edit-admin-commission.component.css'],
  providers: [AdminService, HomeFormBuilderService]
})
export class EditAdminCommissionComponent implements OnInit {

  companyName: any;
  nameOfTheContact: any;
  remuneration:any;
  telephone: any;
  address: any;
  city: any;
  pays: any;
  tradeRegisterNumber: any;
  pseudo: any;
  pinCode: any;

  jobId: any;

  pageLoading = false;
  getItemPageLoading = true;
  loginUser: any;
  items = [];
  message: string;
  alertClass: string;
  alert: string;
  editcommissionForm: FormGroup;

  formSubmitted = false;
  filedata: any;
  fileEvent(e) {
    this.filedata = e.target.files[0];
  }
  constructor(
    private homeFormBuilderService: HomeFormBuilderService,
    private route: ActivatedRoute,
    private router: Router,
    private homeApiService: HomeApiService,
    private adminService: AdminService
  ) { }


  ngOnInit() {
    this.editcommissionForm =  this.homeFormBuilderService.adminCommissionFormBuilder();
    this.loginUser = JSON.parse(localStorage.getItem('appAdmin'));
    this.pageLoading = true;
    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
    this.getItemInfo();
  }
  

  // convenience getter for easy access to form fields
  get f() { return this.editcommissionForm.controls; }

  saveProfile() {
    this.pageLoading = true;
    const reqData = this.editcommissionForm.value;
    this.adminService.updateDiponibility(reqData,this.loginUser.userId)
    .subscribe(
      res => {
        console.log("Response: ", res);
        if (res['success']) {
          this.alertClass = "alert-success";
          this.message = res['message'];
        } else {
          this.alertClass = "alert-danger";
          this.message = res['message'];
        }
        this.pageLoading = false;
        this.router.navigate(['/admin/commission']);
      },
      error => {
        console.log("Error: ", error);
        if (error.status === 401) {
          this.alertClass = "alert-danger";
          this.message = error.error['message'];
        }
     
        this.pageLoading = false;
      }
    );
    
  return false;
}

  

  onReset() {
    this.formSubmitted = false;
   // this.editProfileForm.reset();
  }
  getItemInfo() {

    this.homeApiService.admincommision(this.loginUser.userToken)
      .subscribe(
        res => {
          const itemINfo = res['data'];
         console.log(itemINfo);
          if (res['success']) {

            let formData: any = [];
            formData.product1 = itemINfo.product1 || "";
            formData.product2 = itemINfo.product2 || "";
            formData.product3 = itemINfo.product3 || "";
            formData.product4 = itemINfo.product4 || "";
            formData.product5 = itemINfo.product5 || "";
            formData.isFixed = itemINfo.isFixed || "";
            formData.fixed = itemINfo.fixed || "";
            this.editcommissionForm.patchValue(formData);

          } else {
            this.alert = "alert-danger";
            this.message = res['message'];
          }
          this.getItemPageLoading = false;
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.getItemPageLoading = false;
          this.pageLoading = false;
        }
      );
  }
  changeEvent(event:any){
    if (this.f.isFixed.value == "Yes") {
      this.editcommissionForm.get('fixed').setValidators([Validators.required]);
      this.editcommissionForm.get('product1').setValidators([]);
      this.editcommissionForm.get('product2').setValidators([]);
      this.editcommissionForm.get('product3').setValidators([]);
      this.editcommissionForm.get('product4').setValidators([]);
      this.editcommissionForm.get('product5').setValidators([]);
    } else {
      this.editcommissionForm.get('fixed').setValidators([]);
      this.editcommissionForm.get('product1').setValidators([Validators.required]);
      this.editcommissionForm.get('product2').setValidators([Validators.required]);
      this.editcommissionForm.get('product3').setValidators([Validators.required]);
      this.editcommissionForm.get('product4').setValidators([Validators.required]);
      this.editcommissionForm.get('product5').setValidators([Validators.required]);
    }  
    this.editcommissionForm.get('fixed').updateValueAndValidity();
    this.editcommissionForm.get('product1').updateValueAndValidity();
    this.editcommissionForm.get('product2').updateValueAndValidity();
    this.editcommissionForm.get('product3').updateValueAndValidity();
    this.editcommissionForm.get('product4').updateValueAndValidity();
    this.editcommissionForm.get('product5').updateValueAndValidity();

    
  }

}
