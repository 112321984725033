import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

import { ErrorHandlingService } from '../error-handling.service';

@Injectable({
  providedIn: 'root'
})
export class HomeApiService {
  url: any = environment.apiUrl;

  // APIs
  jobApi = this.url + '/api/jobs';
  myAccountAPI = this.url + '/api/user-details';
  postADisponsibility = this.url + '/api/installer-availability';
  installerAvailabilityApi = this.url + '/api/installer-availability';
  JobRequestApi = this.url + '/api/pending-request';
  acceptJob = this.url + '/api/accept-request';
  DoneJobAPI = this.url + '/api/done-job-listing';
  completeJobApi = this.url + '/api/complete-job';
  createPaymentReqApi = this.url + '/api/create-payment-req';
  adminCommisionApi = this.url + '/api/admin-commision';
  getInstallerAvailabilityApi = this.url + '/api/get-installer-availability';
  getInstallerAvailabilityListingApi = this.url + '/api/get-installer-availability-listing';
  getInstallerAvailabilityListingCallcenterApi = this.url + '/api/get-installer-availability-callcenter-listing';
  userChatNotificationsApi = this.url + '/api/userChatNotifications';


  constructor(
    public errorHandlingService: ErrorHandlingService,
    public httpClient: HttpClient
  ) { }

  // Job listing
  joblisting(userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.jobApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  userChatNotifications(userToken){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.userChatNotificationsApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  admincommision(userToken){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.adminCommisionApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Job listing
  DoneJobListing(userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.DoneJobAPI, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // add appointment
  addAppointment(reqData: any, userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.post(this.jobApi, reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Job listing
  viewJob(userToken, jobId: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.jobApi + '/' + jobId, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Job listing
 myAccount (userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    console.log(this.myAccountAPI);
    return this.httpClient.post(this.myAccountAPI, [], options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Post A Disponsibility Data
  postADisponsibilityData(reqData: any, userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.post(this.postADisponsibility, reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Post A Disponsibility Data
  getInstallerAvailability(installerAvailabilityId) {
    let loginUser = JSON.parse(localStorage.getItem('installerUser')) || JSON.parse(localStorage.getItem('appUser'));
  
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + loginUser.userToken
    });

    const options = { headers: headers };
    return this.httpClient.get(this.installerAvailabilityApi + "/" + installerAvailabilityId, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  } 

  // Installer Availability Listing
  InstallerAvailabilityListing(userToken,installerAvailabilityId) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.getInstallerAvailabilityListingApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

   // Callcenter Availability Listing
   InstallerAvailabilityCallcenterListing(userToken,installerAvailabilityId) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.getInstallerAvailabilityListingCallcenterApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  


   // Post A Disponsibility Data
  InstallerAvailability(userToken,userId) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.getInstallerAvailabilityApi+ '/' + userId, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  

  jobrequest(userToken, jobId: any){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.JobRequestApi + '/' + jobId, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );

  }

    // Post A Disponsibility Data
    acceptOdffer(reqData: any, userToken) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userToken
      });
      const options = { headers: headers };
      return this.httpClient.post(this.acceptJob, reqData, options)
        .pipe(
          map((response: Response) => { return response; }),
          catchError(error => this.errorHandlingService.handleError(error))
        );
    }

    completeJob(reqData: any, userToken){
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userToken
      });
      const options = { headers: headers };
      return this.httpClient.post(this.completeJobApi, reqData, options)
        .pipe(
          map((response: Response) => { return response; }),
          catchError(error => this.errorHandlingService.handleError(error))
        );
    }
    createPaymentReq(reqData: any, userToken){
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userToken
      });
      const options = { headers: headers };
      return this.httpClient.post(this.createPaymentReqApi, reqData, options)
        .pipe(
          map((response: Response) => { return response; }),
          catchError(error => this.errorHandlingService.handleError(error))
        );
    }
    

}
