import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../_guard/auth.guard'; 
import { AdminGuard } from '../_guard/admin.guard'; 
import { InstallerGuard } from '../_guard/installer.guard'; 

// App Pages
import { HomeLoginComponent } from '../home/components/home-login/home-login.component';
import { HomeSignupComponent } from '../home/components/home-signup/home-signup.component';
import { HomeForgotPasswordComponent } from '../home/components/home-forgot-password/home-forgot-password.component';
import { HomeResetPasswordComponent } from '../home/components/home-reset-password/home-reset-password.component';
import { SignUpLinkComponent } from '../home/layout/sign-up-link/sign-up-link.component';
import { CallCenterSignupComponent } from '../home/components/call-center-signup/call-center-signup.component';
import { InstallerSignupComponent } from '../home/components/installer-signup/installer-signup.component';
import { HomeSignUpSuccessComponent } from '../home/components/home-signup/home-sign-up-success/home-sign-up-success.component';
import { HomeEmailVerificationComponent } from '../home/components/home-email-verification/home-email-verification.component';

// Application Routes
const routes: Routes = [
  { path: '', component: HomeLoginComponent },  
  { path: 'login', component: HomeLoginComponent },
  { path: 'signup', component: SignUpLinkComponent },
  { path: 'signup-callcenter', component: CallCenterSignupComponent },
  { path: 'signup-installer', component: InstallerSignupComponent },
  { path: 'signup-success', component: HomeSignUpSuccessComponent },
  { path: 'email-verification/:id', component: HomeEmailVerificationComponent },
  { path: 'forgot-password', component: HomeForgotPasswordComponent },
  { path: 'forgot-password/:id', component: HomeResetPasswordComponent },
  { path: 'user', loadChildren: () => import('../user/user.module').then(m => m.UserModule), canActivate: [AuthGuard]},
  { path: 'installer', loadChildren: () => import('../installer/installer.module').then(m => m.InstallerModule), canActivate: [InstallerGuard]},
  { path: 'admin', loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule), canActivate: [AdminGuard]},
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes,{useHash : true})],
  exports: [RouterModule]
})
export class AppRoutesModule { }
