import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../_modules/shared.module';
import { AdminRoutingModule } from '../_routes/admin-routing.module';
import { AdminComponent } from './admin.component';
import { ModalModule } from '../_modal';
// Layout Components
import { AdminHeaderComponent } from './layout/admin-header/admin-header.component';
import { AdminFooterComponent } from './layout/admin-footer/admin-footer.component';
import { AdminSidebarComponent } from './layout/admin-sidebar/admin-sidebar.component';

// Admin Components
import { AdminUpdatePasswordComponent } from './components/admin-update-password/admin-update-password.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';

// Admin Pages
import { AdminCallCenterListComponent } from './pages/admin-call-center-list/admin-call-center-list.component';
import { AdminEditCallCenterComponent } from './pages/admin-call-center-list/admin-edit-call-center/admin-edit-call-center.component';
import { AdminJobListComponent } from './pages/admin-job-list/admin-job-list.component';
import { AdminViewJobsComponent } from './pages/admin-job-list/admin-view-jobs/admin-view-jobs.component';
import { AdminInstallerListComponent } from './pages/admin-installer-list/admin-installer-list.component';
import { AdminEditInstallerComponent } from './pages/admin-installer-list/admin-edit-installer/admin-edit-installer.component';

import { AdminChatComponent } from './pages/admin-chat/admin-chat.component';
import { AdminUserDetailComponent } from './pages/admin-user-detail/admin-user-detail.component';
import { AdminJobPaymentRequestComponent } from './pages/admin-job-payment-request/admin-job-payment-request.component';
import { AdminJobChatComponent } from './pages/admin-job-chat/admin-job-chat.component';
import { AdminRemainingBalanceComponent } from './pages/admin-remaining-balance/admin-remaining-balance.component';
import { AdminDiponibilityComponent } from './pages/admin-diponibility/admin-diponibility.component';
import { AdminCommissionComponent } from './pages/admin-commission/admin-commission.component';
import { EditAdminCommissionComponent } from './pages/edit-admin-commission/edit-admin-commission.component';

@NgModule({
  declarations: [
    AdminComponent,
    AdminHeaderComponent, 
    AdminFooterComponent, 
    AdminSidebarComponent,     
    AdminUpdatePasswordComponent, 
    AdminDashboardComponent, 
    AdminCallCenterListComponent, 
    AdminInstallerListComponent, 
    AdminChatComponent, 
    AdminUserDetailComponent,
    AdminJobListComponent,
    AdminViewJobsComponent,
    AdminJobPaymentRequestComponent,
    AdminJobChatComponent,
    AdminEditInstallerComponent,
    AdminEditCallCenterComponent,
    AdminRemainingBalanceComponent,
    AdminDiponibilityComponent,
    AdminCommissionComponent,
    EditAdminCommissionComponent
  ],
  imports: [
    CommonModule,
    ModalModule,
    SharedModule,
    AdminRoutingModule,
    ReactiveFormsModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AdminModule { }
