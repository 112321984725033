import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,  Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { InstallerService } from '../../../_services/installer/installer.service';
import { InstallerFormBuilderService } from '../../../_services/installer/installer-form-builder.service';
@Component({
  selector: 'app-installer-take-job',
  templateUrl: './installer-take-job.component.html',
  styleUrls: ['./installer-take-job.component.css'],
  providers: [InstallerService,InstallerFormBuilderService]
})
export class InstallerTakeJobComponent implements OnInit {
  jobId:any;
  jobIdD:any;
  takeJobForm: FormGroup;
  formSubmitted = false;
  pageLoading = false;
  loginUser:any;
  items:any;  
  message: string;
  alertClass: string;
  alert:string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder:FormBuilder,
    private installerService: InstallerService,
    private installerFormBuilderService: InstallerFormBuilderService
  ) { 
    this.jobIdD = this.route.snapshot.paramMap.get("jobId");
    this.jobId = atob(this.route.snapshot.paramMap.get("jobId"));
  }
  get f() { return this.takeJobForm.controls; }
  ngOnInit() {
    this.takeJobForm = this.installerFormBuilderService.takeJobFormBuilder();

    this.loginUser = JSON.parse (localStorage.getItem('installerUser'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
    this.pageLoading = true;
    this.installerService.viewJob(this.loginUser.userToken, this.jobId)
      .subscribe(
        res => {
          this.items = res['data'];
          if (!res['success']) {
            this.alert = "alert-danger";             
            this.message = res['message'];
          } 
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );

  }
  onSubmit() {
    this.formSubmitted = true;
   
    // stop here if form is invalid
    if (this.takeJobForm.invalid) {
      console.log(this.takeJobForm.controls);
      return;
    }
  
    const reqData = this.takeJobForm.value;
    reqData.jobId = this.jobId;
    this.pageLoading = true;
    this.installerService.takeJob(reqData,this.loginUser.userToken)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.alert = "alert-success";
            this.message = res['message'];
            this.onReset();
          } else {
            this.alert = "alert-danger";             
            this.message = res['message'];
          }
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
    return false;
  }

  onReset() {
    this.formSubmitted = false;
    this.takeJobForm.reset();
  }



}
