import { Injectable } from '@angular/core';
import { FormBuilder,  FormArray, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class InstallerFormBuilderService {

  constructor(
    private formBuilder: FormBuilder
  ) { }

  // Sign up Form Builder installationDate
  addWarehouseFormBuilder () {
    return this.formBuilder.group({
      warehouseName: ['', [Validators.required]],
      warehouseDepartments: this.formBuilder.array([this.formBuilder.group({
        dept: ['', [Validators.required]]
      })]),
    });

  }
  takeJobFormBuilder () {
    return this.formBuilder.group({
      comment: ['', [Validators.required]]
    });

  }
}


