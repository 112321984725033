import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators } from '@angular/forms';

import { HomeApiService } from '../../../../_services/home/home-api.service';
import { UserFormBuilderService } from '../../../../_services/user/user-form-builder.service';

@Component({
  selector: 'app-edit-user-jobs',
  templateUrl: './edit-user-jobs.component.html',
  styleUrls: ['./edit-user-jobs.component.css']
})
export class EditUserJobsComponent implements OnInit {
  jobId:any;
  addAppointmentForm: FormGroup;
  formSubmitted = false;
  pageLoading = false;
  loginUser:any;
  items:any;  
  message: string;
  alertClass: string;
  alert:string;
  showRoofHeight = false;
  isAlreadyTrue = false;
  isExistingInsulation = false;
  uniqueNumber = this.getUniqueNumber();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private homeApiService: HomeApiService,
    private userFormBuilderService: UserFormBuilderService
  ) { 
    this.jobId = atob (this.route.snapshot.paramMap.get("jobId"));
  }
  
  ngOnInit() {
    this.addAppointmentForm = this.userFormBuilderService.addAppointmentForm();
    this.loginUser = JSON.parse (localStorage.getItem('appUser'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }

    this.pageLoading = true;
    this.homeApiService.viewJob(this.loginUser.userToken, this.jobId)
      .subscribe(
        res => {
          this.items = res['data'];
          this.items.installationDate = new Date(this.items.installationDate);
          this.items.sinceWhen = new Date(this.items.sinceWhen);
          this.detuilleChange(this.items.detuille);
          this.alreadyTrue(this.items.alreadyRefuse);
          this.existingInsulation(this.items.existingInsulation);
          this.addAppointmentForm.patchValue(this.items);
          console.log("Response: ", res);

          if (!res['success']) {
            this.alert = "alert-danger";             
            this.message = res['message'];
          } 
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );

  }

  
  existingInsulation (event:any) {
    if(event == 'Yes'){
      this.isExistingInsulation = true;

      this.addAppointmentForm.get('typeOfExistingInsulation').setValidators([Validators.required]);
      this.addAppointmentForm.get('sinceWhen').setValidators([Validators.required]);
      this.addAppointmentForm.get('typeOfExistingInsulation').updateValueAndValidity();
      this.addAppointmentForm.get('sinceWhen').updateValueAndValidity();
     }else if (this.f.existingInsulation.value == "yes") {
      this.isExistingInsulation = true;

      this.addAppointmentForm.get('typeOfExistingInsulation').setValidators([Validators.required]);
      this.addAppointmentForm.get('sinceWhen').setValidators([Validators.required]);
      this.addAppointmentForm.get('typeOfExistingInsulation').updateValueAndValidity();
      this.addAppointmentForm.get('sinceWhen').updateValueAndValidity();
    } else {
      this.isExistingInsulation = false;  

      this.addAppointmentForm.get('typeOfExistingInsulation').setValidators([]);
      this.addAppointmentForm.get('sinceWhen').setValidators([]);
      this.addAppointmentForm.get('typeOfExistingInsulation').updateValueAndValidity();
      this.addAppointmentForm.get('sinceWhen').updateValueAndValidity();
    }      
  }

  detuilleChange (event:any){
 
     if(event == 'Yes'){
      this.showRoofHeight = true;

      this.addAppointmentForm.get('roofHeight').setValidators([Validators.required]);
      this.addAppointmentForm.get('roofHeight').updateValueAndValidity();
     }else if (this.f.detuille.value == "yes") {
      this.showRoofHeight = true;

      this.addAppointmentForm.get('roofHeight').setValidators([Validators.required]);
      this.addAppointmentForm.get('roofHeight').updateValueAndValidity();
    } else {
      this.showRoofHeight = false; 
      this.addAppointmentForm.get('roofHeight').setValidators([]);
      this.addAppointmentForm.get('roofHeight').updateValueAndValidity();
    }      
  }

  alreadyTrue (event:any) {
    if(event == 'Yes'){
      this.isAlreadyTrue = true;

      this.addAppointmentForm.get('reasonForRefusal').setValidators([Validators.required]);
      this.addAppointmentForm.get('reasonForRefusal').updateValueAndValidity();
     }else if (this.f.alreadyRefuse.value == "yes") {
      this.isAlreadyTrue = true;

      this.addAppointmentForm.get('reasonForRefusal').setValidators([Validators.required]);
      this.addAppointmentForm.get('reasonForRefusal').updateValueAndValidity();
    } else {
      this.isAlreadyTrue = false;  

      this.addAppointmentForm.get('reasonForRefusal').setValidators([]);
      this.addAppointmentForm.get('reasonForRefusal').updateValueAndValidity();
    }      
  }

  getUniqueNumber () {
    let now = new Date();    
    return now.getFullYear().toString() + "" + now.getMonth () + "" + now.getHours() + "" + now.getMinutes() + "" + now.getMilliseconds();
  }

  // convenience getter for easy access to form fields
  get f() { return this.addAppointmentForm.controls; }

  onSubmit() {
    this.formSubmitted = true;
   
    // stop here if form is invalid
    if (this.addAppointmentForm.invalid) {
      console.log(this.addAppointmentForm.controls);
      return;
    }
  
    const reqData = this.addAppointmentForm.value;
    reqData.userId = this.loginUser.userId;
    reqData.jobId = this.items.jobId;
    this.pageLoading = true;
    console.log(reqData);
    this.homeApiService.addAppointment(reqData,this.loginUser.userToken)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.alert = "alert-success";
            this.message = res['message'];
            
          } else {
            this.alert = "alert-danger";             
            this.message = res['message'];
          }
          this.pageLoading = false;
          
          this.router.navigate(['/user/jobs']);

        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
    return false;
  }

  onReset() {
    this.formSubmitted = false;
    this.addAppointmentForm.reset();
  }

}
