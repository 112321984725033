import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-sign-up-success',
  templateUrl: './home-sign-up-success.component.html',
  styleUrls: ['./home-sign-up-success.component.css']
})
export class HomeSignUpSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
