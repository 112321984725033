import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HomeApiService } from '../../../_services/home/home-api.service';
import { UserFormBuilderService } from '../../../_services/user/user-form-builder.service';

@Component({
  selector: 'app-admin-commission',
  templateUrl: './admin-commission.component.html',
  styleUrls: ['./admin-commission.component.css']
})
export class AdminCommissionComponent implements OnInit {

  public multiSelection: boolean = true;
  public dates: Date[];

  addAppointmentForm: FormGroup;
  showRoofHeight = true;
  formSubmitted: any;

  jobId: any;
  warehouseList: any;
  warehouses: any;
  pageLoading = false;
  getPageLoading = false;
  loginUser = JSON.parse(localStorage.getItem('appAdmin'));
  items: any;
  message: string;
  alertClass: string;
  alert: string;

  isAlreadyTrue = false;
  isExistingInsulation = false;

  isMorning = "No";
  isAfterNoon = "No";
  isRampant = "No";
  isDetuille = "No";
  isolationName: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private homeApiService: HomeApiService
  ) { }

  ngOnInit() {
    // this.dates = [new Date("2020/01/10"), new Date("2020/01/14"), new Date("2020/01/18")];
  

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }

    this.pageLoading = true;
    this.homeApiService.admincommision(this.loginUser.userToken)
      .subscribe(
        res => {
          this.items = res['data'];
          if (!res['success']) {
            this.alert = "alert-danger";
            this.message = res['message'];
          }
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
  }

}
