import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';

import { HomeApiService } from '../../../../_services/home/home-api.service';
import { UserFormBuilderService } from '../../../../_services/user/user-form-builder.service';

@Component({
  selector: 'app-view-installer',
  templateUrl: './/view-installer.component.html',
  styleUrls: ['./view-installer.component.css']
})
export class ViewInstallerComponent implements OnInit {

  public multiSelection: boolean = true;
  public dates: Date[];

  addAppointmentForm: FormGroup;
  showRoofHeight = true;
  formSubmitted: any;

  userId:any;
  warehouseList:any;
  warehouses:any;
  pageLoading = false;
  getPageLoading = false;
  loginUser:any;
  items:any;  
  message: string;
  alertClass: string;
  alert:string;

  isAlreadyTrue = false;
  isExistingInsulation = false;

  isMorning   = "No";
  isAfterNoon = "No";
  isRampant   = "No";
  isDetuille  = "No";
  isolationName:any;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private homeApiService: HomeApiService
  ) { 
    this.userId = atob (this.route.snapshot.paramMap.get("userId"));
  }

  ngOnInit() {
    // this.dates = [new Date("2020/01/10"), new Date("2020/01/14"), new Date("2020/01/18")];
    this.loginUser = JSON.parse(localStorage.getItem('appUser'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
  
    this.pageLoading = true;
    this.homeApiService.InstallerAvailability(this.loginUser.userToken,this.userId)
      .subscribe(
        res => {
          this.items = res['data']['detail'];
          this.warehouseList = res['data']['wharehouselist'];
          this.warehouses = res['data']['warehouses'];
          this.isMorning   = (this.items.morning == 1) ? "Yes": "No";
          this.isAfterNoon = (this.items.afterNoon == 1) ? "Yes": "No";
          this.isRampant = (this.items.rampant == 1) ? "Yes": "No";
          this.isDetuille = (this.items.detuille == 1) ? "Yes": "No";

          if (this.items.availabilityDates != undefined) {
            let availDates = this.items.availabilityDates.split(",");
            if (availDates.length > 0) {
              var output = new Array();
              availDates.forEach(element => {
                output.push (new Date(element));
              });
              this.dates = output;
            }
          }

          if (this.items.insulationPossibleId == 1) {
            this.isolationName = "Propels";
          } else if (this.items.insulationPossibleId == 2) {
            this.isolationName = "Glass wool";
          } else if (this.items.insulationPossibleId == 3) {
            this.isolationName = "Roll out";
          } else if (this.items.insulationPossibleId == 4) {
            this.isolationName = "Tetris";
          } else if (this.items.insulationPossibleId == 5) {
            this.isolationName = "Other";
          }


          if (!res['success']) {
            this.alert = "alert-danger";             
            this.message = res['message'];
          } 
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
  }
}
