import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

import { ErrorHandlingService } from '../error-handling.service';

@Injectable({
  providedIn: 'root'
})
export class InstallerService {

  url: any = environment.apiUrl;

  // APIs
  jobApi = this.url + '/api/jobs';
  availableJobs = this.url + '/api/installer-available-jobs';
  progressJobs = this.url + '/api/installer-progress-jobs';
  sendJobRequest = this.url + '/api/sendJobRequest';
  warehouseApi = this.url + '/api/wharehouse';
  myAccountAPI = this.url + '/api/user-details';
  takecallApi = this.url + '/api/twilloMakeAVoiceCall';
  installerAccountinglistApi = this.url + '/api/installer-accounting-list';
 
  constructor(
    public errorHandlingService: ErrorHandlingService,
    public httpClient: HttpClient
  ) { }

  // Job listing
  joblisting(userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.jobApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  // Job listing
 accountinglisting(userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.installerAccountinglistApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  
  // Job listing
  availableJobListing(userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.availableJobs, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  // Job listing
  progressJobLsiting(userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.progressJobs, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  
   // Job listing
   warehouseListing(userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.warehouseApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  addWarehouse(reqData: any, userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.post(this.warehouseApi, reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  takeJob(reqData: any, userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.post(this.sendJobRequest, reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  takecall(reqData: any, userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.post(this.takecallApi, reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  editWarehouse(reqData: any,warehouseId: any, userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });warehouseId
    const options = { headers: headers };
    return this.httpClient.put(this.warehouseApi+ '/' + warehouseId, reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // add appointment
  addAppointment(reqData: any, userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.post(this.jobApi, reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Job listing
  viewJob(userToken, jobId: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.jobApi + '/' + jobId, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }


  // Job listing
  viewWarehouse(userToken, warehouseId: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.warehouseApi + '/' + warehouseId, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  // Job listing
  myAccount(userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    console.log(this.myAccountAPI);
    return this.httpClient.post(this.myAccountAPI, [], options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
}
