import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';


import { InstallerService } from '../../../../_services/installer/installer.service';

import { InstallerFormBuilderService } from '../../../../_services/installer/installer-form-builder.service';
@Component({
  selector: 'app-installer-edit-warehouse',
  templateUrl: './installer-edit-warehouse.component.html',
  styleUrls: ['./installer-edit-warehouse.component.css'],
  providers: [InstallerService, InstallerFormBuilderService]
})
export class InstallerEditWarehouseComponent implements OnInit {
  warehouseId:any;
  addwarehousForm: FormGroup;
  formSubmitted = false;
  pageLoading = false;
  loginUser:any;
  items:any;  
  message: string;
  alertClass: string;
  alert:string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder:FormBuilder,
    private installerService: InstallerService,
    private installerFormBuilderService: InstallerFormBuilderService
  ) { 
    this.warehouseId = atob(this.route.snapshot.paramMap.get("warehouseId"));
  }
  get f() { return this.addwarehousForm.controls; }
  ngOnInit() {
   
    this.addwarehousForm = this.installerFormBuilderService.addWarehouseFormBuilder();

    this.loginUser = JSON.parse (localStorage.getItem('installerUser'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
    this.pageLoading = true;
    this.installerService.viewWarehouse(this.loginUser.userToken, this.warehouseId)
      .subscribe(
        res => {
          this.items = res['data'];
          if (Object.keys(this.items).length !== 0) {
            let indexAdd = 1;
            if (this.items.warehouseDepartments.length > 0) {
              this.items.warehouseDepartments.forEach(element => {
                if (indexAdd < this.items.warehouseDepartments.length) {
                  this.addwarehouseDepartment();
                }
                indexAdd = indexAdd + 1;
              });
            } else {
              let addOnIndex = 0;
              this.warehouseDepartments.controls.forEach(element => {
                this.deletewarehouseDepartment(addOnIndex);
                addOnIndex = addOnIndex +1;
              });
            }
            this.addwarehousForm.patchValue(this.items);
          }
      
          if (!res['success']) {
            this.alert = "alert-danger";             
            this.message = res['message'];
          } 
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );

  }
  get warehouseDepartments() {
    return this.addwarehousForm.get('warehouseDepartments') as FormArray;
  }
  addwarehouseDepartment() {
    this.warehouseDepartments.push(this.formBuilder.group({
      dept: ['', [Validators.required]]
     
    }));
  }
  deletewarehouseDepartment(index) {
    if (this.warehouseDepartments.length > 1)
      this.warehouseDepartments.removeAt(index);
    else 
      alert ("One Department on is Required");
  }

  
  onSubmit() {
    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.addwarehousForm.invalid) {
      console.log(this.addwarehousForm.controls);
      return;
    }
  
    const reqData = this.addwarehousForm.value;
    reqData.installerId = this.loginUser.userId;
    this.pageLoading = true;
    this.installerService.editWarehouse(reqData, this.warehouseId,this.loginUser.userToken)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.alert = "alert-success";
            this.message = res['message'];
            this.onReset();
          } else {
            this.alert = "alert-danger";             
            this.message = res['message'];
          }
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
    return false;
  }

  onReset() {
    this.formSubmitted = false;
   // this.addwarehousForm.reset();
  }
   // convenience getter for easy access to form fields
   

}
