import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../_services/home/auth.service';
import { HomeFormBuilderService } from '../../../_services/home/home-form-builder.service';
@Component({
  selector: 'app-home-reset-password',
  templateUrl: './home-reset-password.component.html',
  styleUrls: ['./home-reset-password.component.scss'],
  providers: [AuthService, HomeFormBuilderService] 
})
export class HomeResetPasswordComponent implements OnInit {
    userDetail:any;
    ResetPasswordForm: FormGroup;
    formSubmitted = false;
    pageLoading = false;
  
    message: string;
    alert: string;

    constructor(
        private authService: AuthService,
        public router: Router,
        private route: ActivatedRoute,
        private homeFormBuilderService: HomeFormBuilderService
    ) { }

    ngOnInit() {
        const user_id = +this.route.snapshot.paramMap.get('id');
        this.authService.reset_url(user_id)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.userDetail = res['data'];
          } else {
            this.router.navigate(['/not-found']);
          }
        },
        error => {
         this.router.navigate(['/not-found']);
        }
      );
    this.ResetPasswordForm = this.homeFormBuilderService.resetFormBuilder();
   
    }

    // convenience getter for easy access to form fields
    get f() { return this.ResetPasswordForm.controls; }

    onSubmit() {
        this.formSubmitted = true;
        // stop here if form is invalid
        if (this.ResetPasswordForm.invalid) {
            return;
        }
        this.ResetPasswordForm.value.email = this.userDetail.email;
        this.ResetPasswordForm.value.token = this.userDetail.token;
        const reqData = this.ResetPasswordForm.value;
        this.pageLoading = true;
    
        this.authService.reset_password(reqData)
          .subscribe(
            res => {
              console.log("Response: ", res);
              if (res['success']) {
                this.onReset();
                this.alert = "alert-success";
                this.message = res['message'];
              } else {
                this.alert = "alert-danger";
                this.message = res['message'];
              }
              this.pageLoading = false;
            },
            error => {
              console.log(error);
    
              if (error.status === 401) {
                this.alert = "alert-danger";
                this.message = error.error['message'];
              }
              this.pageLoading = false;
            }
          );
        return false;
    }

    onReset() {
        this.formSubmitted = false;
        this.ResetPasswordForm.reset();
    }  
}


