import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-loader',
  templateUrl: './list-loader.component.html',
  styleUrls: ['./list-loader.component.css']
})
export class ListLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
