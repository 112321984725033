// Angular Modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Parent Component
import { AppComponent } from './app.component';

// App Modules
import { HomeModule } from './home/home.module';
import { UserModule } from './user/user.module';
import { InstallerModule } from './installer/installer.module';

import { AppRoutesModule } from './_routes/app-routes.module';
import { AdminModule } from './admin/admin.module';


@NgModule({
  imports: [
    CommonModule,    
    BrowserModule,
    BrowserAnimationsModule,
    HomeModule,
    AppRoutesModule,
    UserModule,
    InstallerModule,
    AdminModule
  ],
  declarations: [AppComponent], 
  providers: [],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
