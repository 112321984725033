import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeApiService } from '../../../../_services/home/home-api.service';

@Component({
  selector: 'app-view-user-jobs',
  templateUrl: './view-user-jobs.component.html',
  styleUrls: ['./view-user-jobs.component.css']
})
export class ViewUserJobsComponent implements OnInit {
  jobId:any;
  jobIdBtob:any;

  pageLoading = true;
  loginUser:any;
  items: any;
  message: string;
  alertClass: string;
  alert:string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private homeApiService: HomeApiService
  ) {
    this.jobId = atob (this.route.snapshot.paramMap.get("jobId"));
    this.jobIdBtob = this.route.snapshot.paramMap.get("jobId");
  }

  ngOnInit() {
    this.loginUser = JSON.parse (localStorage.getItem('appUser'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }

    this.pageLoading = true;
    this.homeApiService.viewJob(this.loginUser.userToken, this.jobId)
      .subscribe(
        res => {
          this.items = res['data'];
          console.log("Response: ", res);

          if (!res['success']) {
            this.alert = "alert-danger";             
            this.message = res['message'];
          } 
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
  }

}