import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

// Admin Layout Component 
import { AdminComponent } from '../admin/admin.component';

import { AdminDashboardComponent } from '../admin/components/admin-dashboard/admin-dashboard.component';
import { AdminUpdatePasswordComponent } from '../admin/components/admin-update-password/admin-update-password.component';
import { AdminInstallerListComponent } from '../admin/pages/admin-installer-list/admin-installer-list.component';
import { AdminEditInstallerComponent } from '../admin/pages/admin-installer-list/admin-edit-installer/admin-edit-installer.component';
import { AdminCallCenterListComponent } from '../admin/pages/admin-call-center-list/admin-call-center-list.component';
import { AdminJobListComponent } from '../admin/pages/admin-job-list/admin-job-list.component';
import { AdminViewJobsComponent } from '../admin/pages/admin-job-list/admin-view-jobs/admin-view-jobs.component';
import { AdminUserDetailComponent } from '../admin/pages/admin-user-detail/admin-user-detail.component';
import { AdminJobPaymentRequestComponent } from '../admin/pages/admin-job-payment-request/admin-job-payment-request.component';
import { AdminChatComponent } from '../admin/pages/admin-chat/admin-chat.component';
import { AdminJobChatComponent } from '../admin/pages/admin-job-chat/admin-job-chat.component';
import { AdminEditCallCenterComponent } from '../admin/pages/admin-call-center-list/admin-edit-call-center/admin-edit-call-center.component';
import { AdminRemainingBalanceComponent } from '../admin/pages/admin-remaining-balance/admin-remaining-balance.component';
import { AdminDiponibilityComponent } from '../admin/pages/admin-diponibility/admin-diponibility.component';
import { AdminCommissionComponent } from '../admin/pages/admin-commission/admin-commission.component';
import { EditAdminCommissionComponent } from '../admin/pages/edit-admin-commission/edit-admin-commission.component';

const routes: Routes = [
  {
      path: '',
      component: AdminComponent,
      children: [
        { path: '', component: AdminDashboardComponent },
        { path: 'diponibility', component: AdminDiponibilityComponent },
        { path: 'commission', component: AdminCommissionComponent },
        { path: 'edit-commission', component: EditAdminCommissionComponent },
        { path: 'dashboard', component: AdminDashboardComponent },
        { path: 'update-password', component: AdminUpdatePasswordComponent },
        { path: 'installer-list', component: AdminInstallerListComponent },
        { path: 'job-list', component: AdminJobListComponent },
        { path: 'job-detail/:jobId', component: AdminViewJobsComponent },
        { path: 'job-chat/:jobId', component: AdminJobChatComponent },
        { path: 'call-center-list', component: AdminCallCenterListComponent },
        { path: 'user-detail/:userId', component: AdminUserDetailComponent },
        { path: 'edit-installer/:userId', component: AdminEditInstallerComponent },
        { path: 'payment-request', component: AdminJobPaymentRequestComponent },
        { path: 'edit-call-center/:userId', component: AdminEditCallCenterComponent },
        { path: 'chat', component: AdminChatComponent },
        { path: 'remaining-balance', component: AdminRemainingBalanceComponent}

      ]
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AdminRoutingModule { }
