// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../_modules/shared.module';
import { InstallerRoutingModule } from '../_routes/installer-routing.module';
import { InstallerComponent } from './installer.component';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars';

// Installer Layout
import { InstallerFooterComponent } from './layout/installer-footer/installer-footer.component';
import { InstallerHeaderComponent } from './layout/installer-header/installer-header.component';
import { InstallerSidebarComponent } from './layout/installer-sidebar/installer-sidebar.component';

// Installer Components
import { InstallerMyAccountComponent } from './components/installer-my-account/installer-my-account.component';
import { EditInstallerMyAccountComponent } from './components/installer-my-account/edit-installer-my-account/edit-installer-my-account.component';
import { InstallerUpdatePasswordComponent } from './components/installer-update-password/installer-update-password.component';

// Installer Pages
import { InstallerPostADisponsibilityComponent } from './pages/installer-post-a-disponsibility/installer-post-a-disponsibility.component';
import { InstallerWarehouseComponent } from './pages/installer-warehouse/installer-warehouse.component';
import { InstallerEditWarehouseComponent } from './pages/installer-warehouse/installer-edit-warehouse/installer-edit-warehouse.component';
import { InstallerAddWarehouseComponent } from './pages/installer-warehouse/installer-add-warehouse/installer-add-warehouse.component';
import { InstallerAvailableJobsComponent } from './pages/installer-available-jobs/installer-available-jobs.component';
import { InstallerProgressJobsComponent } from './pages/installer-progress-jobs/installer-progress-jobs.component';
import { InstallerChatComponent } from './pages/installer-chat/installer-chat.component';
import { ViewInstallerAccountingComponent } from './pages/installer-accounting/view-installer-accounting/view-installer-accounting.component';
import { InstallerAccountingComponent } from './pages/installer-accounting/installer-accounting.component';
import { InstallerTakeJobComponent } from './pages/installer-take-job/installer-take-job.component';
import { InstallerJobChatComponent } from './pages/installer-job-chat/installer-job-chat.component';
// import { MyPostedAvailabilityComponent } from './pages/my-posted-availability/my-posted-availability.component';
import { InstallerPostedAvailabilityListingComponent } from './pages/installer-posted-availability-listing/installer-posted-availability-listing.component';
import { EditInstallerPostedAvailabilityComponent } from './pages/installer-posted-availability-listing/edit-installer-posted-availability/edit-installer-posted-availability.component';

@NgModule({
  declarations: [
    InstallerComponent,InstallerFooterComponent, InstallerHeaderComponent, InstallerSidebarComponent, InstallerMyAccountComponent, 
    EditInstallerMyAccountComponent, InstallerUpdatePasswordComponent, InstallerPostADisponsibilityComponent, InstallerWarehouseComponent, 
    InstallerEditWarehouseComponent,  InstallerAddWarehouseComponent, InstallerAvailableJobsComponent, InstallerChatComponent, 
    InstallerAccountingComponent, InstallerTakeJobComponent,InstallerProgressJobsComponent,ViewInstallerAccountingComponent, 
    InstallerJobChatComponent, InstallerPostedAvailabilityListingComponent, EditInstallerPostedAvailabilityComponent
  ],
  imports: [
    CommonModule, SharedModule, CalendarModule, FormsModule, ReactiveFormsModule, InstallerRoutingModule
  ]
})
export class InstallerModule { }
