import { Component, OnInit } from '@angular/core';
import { InstallerService } from '../../../_services/installer/installer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeApiService } from '../../../_services/home/home-api.service';

@Component({
  selector: 'app-installer-list',
  templateUrl: './installer-list.component.html',
  styleUrls: ['./installer-list.component.css']
})
export class InstallerListComponent implements OnInit {
  pageLoading = true;
  loginUser: any;
  items = [];
  pageOfItems: Array<any>;
  message: string;
  alertClass: string;
  alert: string;
  installerAvailabilityId: any;
  installerAvailabilityIdtmp: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private installerService: InstallerService,
    private homeApiService: HomeApiService
  ) {
    this.installerAvailabilityId = atob(this.route.snapshot.paramMap.get("id"));
    this.installerAvailabilityIdtmp = this.route.snapshot.paramMap.get("id");
    console.log("installerAvailabilityId", this.installerAvailabilityId);
  }

  ngOnInit() {
    this.loginUser = JSON.parse(localStorage.getItem('appUser'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }

    this.pageLoading = true;
    this.homeApiService.InstallerAvailabilityCallcenterListing(this.loginUser.userToken, this.installerAvailabilityId)
      .subscribe(
        res => {
          this.items = res['data'];

          if (!res['success']) {
            this.alert = "alert-danger";
            this.message = res['message'];
          }
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
  }

  time(item: any) {
    let str = "";
    if (item.morning == '1') {
      str = "Morning";
    }

    if (item.afterNoon == '1') {
      if (item.morning == '1') {
        str = str + ", ";
      }

      str = str + "Afternoon";
    }

    return str;
  }

  convertDate(item: any) {
    let output = "";
    if (item.detail.availabilityDates != null && item.detail.availabilityDates != undefined) {
      let availDates = item.detail.availabilityDates.split(",");
      if (availDates.length > 0) {

        availDates.forEach(element => {
          output = output + this.formatDate(new Date(element)) + ", ";
        });
      }
    }
    return output;
  }


  formatDate(date) {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    // 28 Jan, 2020

    return day + ' ' + monthNames[monthIndex] + ', ' + year;
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems || [];
  }
  encode(str: any) {
    return btoa(str);
  }

  decode(str: any) {
    return atob(str);
  }

}