import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

import { ErrorHandlingService } from '../error-handling.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  url: any = environment.apiUrl;

 
  dashboardGraphApi = this.url + '/api/admin/dashboardGraph';
  dashboardApi = this.url + '/api/admin/dashboard';
  installerListApi = this.url + '/api/admin/installer-list';
  jobListApi = this.url + '/api/admin/job-list';
  jobDetailApi = this.url + '/api/admin/job-detail';
  callcenterListApi = this.url + '/api/admin/call-center-list';
  userDetailApi = this.url + '/api/admin/getUserDetail';
  userVerifyApi = this.url + '/api/admin/user-verify';
  jobPaymentReqApi = this.url + '/api/admin/job-payment-req';
  remainingBalanceApi = this.url + '/api/admin/remaining-balance';
  paymentDoneApi = this.url + '/api/admin/payment-status';
  editProfileApi = this.url + '/api/admin/editUserDetail';
  updateDiponibilityApi = this.url + '/api/admin/updateDiponibility';
  paidbyinstallerApi = this.url + '/api/admin/paid-by-installer';
  
  // Login User
  loginUser = JSON.parse (localStorage.getItem('appAdmin')) || {};  
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.loginUser.userToken
   });
  constructor(
    public errorHandlingService: ErrorHandlingService,
    public httpClient: HttpClient
  ) { }

  // Job listing
  dashbaord() {
    const options = { headers: this.headers };
    return this.httpClient.get(this.dashboardApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  editInstallerProfile(reqData: any,userId:any) {
    const options = { headers: this.headers };
    return this.httpClient.put(this.editProfileApi+ '/' +userId,reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  updateDiponibility(reqData: any,userId:any) {
    const options = { headers: this.headers };
    return this.httpClient.post(this.updateDiponibilityApi+ '/' +userId,reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Job listing
  dashboardGraph() {
    const options = { headers: this.headers };
    return this.httpClient.get(this.dashboardGraphApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  installerList(userToken) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.installerListApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  jobList() {
    const options = { headers: this.headers };
    return this.httpClient.get(this.jobListApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  remainingBalance() {
    const options = { headers: this.headers };
    return this.httpClient.get(this.remainingBalanceApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  callcenterList() {
    const options = { headers: this.headers };
    return this.httpClient.get(this.callcenterListApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  userDetail(userToken,userId: any){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    return this.httpClient.get(this.userDetailApi+ '/' +userId, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );

  }
  viewJob(userId: any){
    const options = { headers: this.headers };
    return this.httpClient.get(this.jobDetailApi+ '/' +userId, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );

  }
  changeuserStatus(reqData: any){
    const options = { headers: this.headers };
    return this.httpClient.post(this.userVerifyApi,reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  paidbyinstalller(reqData: any){
    const options = { headers: this.headers };
    return this.httpClient.post(this.paidbyinstallerApi,reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  
  paymentDone(reqData: any){
    console.log(reqData);
    const options = { headers: this.headers };
    return this.httpClient.post(this.paymentDoneApi,reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  jobPaymentRequest() {
    const options = { headers: this.headers };
    return this.httpClient.get(this.jobPaymentReqApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  
  
  
}
