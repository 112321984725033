import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,  Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatService } from '../../../_services/chat/chat.service';
import { InstallerService } from '../../../_services/installer/installer.service';
import { InstallerFormBuilderService } from '../../../_services/installer/installer-form-builder.service';

@Component({
  selector: 'app-past-chats',
  templateUrl: './past-chats.component.html',
  styleUrls: ['./past-chats.component.css']
})
export class PastChatsComponent implements OnInit {

  jobId:any;
  jobIdD:any;
  takeJobForm: FormGroup;
  formSubmitted = false;
  pageLoading = false;
  loginUser:any;
  items:any;  
  chats:any;  
  chatId = 0;  
  pageOfItems: Array<any>;

  message: string;
  alertClass: string;
  alert:string;
  msg: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder:FormBuilder,
    private chatService: ChatService,
    private installerService: InstallerService,
    private installerFormBuilderService: InstallerFormBuilderService
  ) { 
    this.jobIdD = this.route.snapshot.paramMap.get("jobId");
    this.jobId = atob(this.route.snapshot.paramMap.get("jobId"));
  }

  get f() { return this.takeJobForm.controls; }

  ngOnInit() {
    this.takeJobForm = this.installerFormBuilderService.takeJobFormBuilder();

    this.loginUser = JSON.parse (localStorage.getItem('appUser'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
    this.pageLoading = true;
    this.chatService.callCenterChats(this.loginUser.userToken)
      .subscribe(
        res => {
          this.items = res['data'];
          if (!res['success']) {
            this.alert = "alert-danger";             
            this.message = res['message'];
          } 
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems || [];
  }

  encode (str:any) {
    return btoa (str);
  }

  decode (str:any) {
    return atob (str);
  }

}
