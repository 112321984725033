import { Component, OnInit } from '@angular/core';
import { HomeApiService } from '../../../_services/home/home-api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-job-request',
  templateUrl: './job-request.component.html',
  styleUrls: ['./job-request.component.css'],
  providers: [HomeApiService]
})
export class JobRequestComponent implements OnInit {
  pageLoading = true;
  jobId:any;
  jobCount: any
  loginUser:any;
  items = [];
  pageOfItems: Array<any>;
  message: string;
  alertClass: string;
  alert:string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private homeApiService: HomeApiService
  ) {
    this.jobId = atob (this.route.snapshot.paramMap.get("jobId"));
  }

  ngOnInit() {
    this.loginUser = JSON.parse (localStorage.getItem('appUser'));

   /* if (!this.loginUser) {
      this.router.navigate(['/login']);
    }*/

    this.pageLoading = true;
    this.homeApiService.jobrequest(this.loginUser.userToken, this.jobId)
      .subscribe(
        res => {
          this.items = res['data']['list'] || [];
          this.jobCount =  res['data']['count_accept'];
          console.log("Response: ", res);
          if (res['success']) {
            this.alertClass = "alert-success";
            this.message = res['message'];
          } else {
            this.alert = "alert-danger";             
            this.message = res['message'];
          }
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
    

  }

  acceptOffer(item){
    if(confirm("Are you sure you want to accept this offer.")){
      const reqData = {};
      reqData['jobId'] = item.jobId;
      reqData['jobRequestId'] = item.jobRequestId;
      reqData['installerId'] = item.userId;
      reqData['amount'] = item.amount;
      item.buttonLoading = true;
      this.homeApiService.acceptOdffer(reqData,this.loginUser.userToken)
        .subscribe(
          res => {
            console.log("Response: ", res);
            if (res['success']) {
              this.alert = "alert-success";
              this.message = res['message'];
              this.jobCount = '1';
            } else {
              this.alert = "alert-danger";             
              this.message = res['message'];
            }
            item.buttonLoading = false;
          },
          error => {
            console.log("Error: ", error);
            if (error.status === 401) {
              this.alert = "alert-danger";
              this.message = error.error['message'];
            }
            item.buttonLoading = false;
          }
        );
    }
    return false;
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems || [];
  }

  encode (str:any) {
    return btoa (str);
  }

  decode (str:any) {
    return atob (str);
  }

}
