import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class HomeFormBuilderService {

  constructor(
    private formBuilder: FormBuilder
  ) { }

  // Login Form Builder
  loginFormBuilder () {
    return this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      remeberMe: ['', []]            
  });
  }

  // Call center sign up Form Builder
  createFormBuilder () {
    return this.formBuilder.group({
      nameOfTheContact: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]] ,
      telephone: ['', [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10),Validators.maxLength(10)]],
      companyName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      pinCode: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      city: ['', [Validators.required]],
      pays: ['', [Validators.required]],
      tradeRegisterNumber: ['', [Validators.required]],
      pseudo: ['', [Validators.required]],
      userType: ['']
    },{
      validator: this.MustMatch('password', 'confirmPassword') 
    });
  }

  // Installer sign up Form Builder
  installerFormBuilder () {
    return this.formBuilder.group({
      nameOfTheContact: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]] ,
      telephone: ['', [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10),Validators.maxLength(10)]],
      companyName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      pinCode: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      city: ['', [Validators.required]],
      pays: ['', [Validators.required]],
      tradeRegisterNumber: ['', [Validators.required]],
      confirmerPhoneNumber: ['', [Validators.required]],
      remuneration: ['', [Validators.required]],
      pseudo: ['', [Validators.required]],
      userType: ['']
    },{
      validator: this.MustMatch('password', 'confirmPassword') 
    });
  }

  // Update Password Form Builder
  updatePasswordFormBuilder () {
    return this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    },{
      validator: this.MustMatch('newPassword', 'confirmPassword') 
    });
  }

  // Forgot Password Form Builder
  forgotFormBuilder () {
    return this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
  
  // Reset Password Form Builder
  resetFormBuilder () {
    return this.formBuilder.group({
      password: ['', [Validators.required]],
      c_password: ['', [Validators.required]],
      token: [''],
      email:  ['']
    },{
      validator: this.MustMatch('password', 'c_password') 
    });
  }

  // Sign up Form Builder
  editInsulatorFormBuilder () {
    return this.formBuilder.group({
      nameOfTheContact: ['', [Validators.required]],
      telephone: ['', [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10),Validators.maxLength(10)]],
      companyName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      pinCode: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      city: ['', [Validators.required]],
      pays: ['', [Validators.required]],
      tradeRegisterNumber: ['', [Validators.required]],
      pseudo: ['', [Validators.required]],
      remuneration: ['', [Validators.required]],
      img:[''],
      product1: [''],
      product2: [''],
      product3: [''],
      product4: [''],
      product5: ['']
    });
  }
  adminCommissionFormBuilder(){
    return this.formBuilder.group({
      product1: [''],
      product2: [''],
      product3: [''],
      product4: [''],
      product5: [''],
      isFixed: ['', [Validators.required]],
      fixed:[''],

    });

  }
  editProfileFormBuilder () {
    return this.formBuilder.group({
      nameOfTheContact: ['', [Validators.required]],
      telephone: ['', [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10),Validators.maxLength(10)]],
      companyName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      pinCode: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      city: ['', [Validators.required]],
      pays: ['', [Validators.required]],
      tradeRegisterNumber: ['', [Validators.required]],
      pseudo: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      accountNumber: ['', [Validators.required]],
      accountHolderName: ['', [Validators.required]],
      ifscCode: ['', [Validators.required]],
      img:[''],

    });
  }


 MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
}
