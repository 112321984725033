import { Component, OnInit } from '@angular/core';
import { InstallerService } from '../../../_services/installer/installer.service';
import { Router } from '@angular/router';
import { ChatService } from '../../../_services/chat/chat.service';

@Component({
  selector: 'app-installer-available-jobs',
  templateUrl: './installer-available-jobs.component.html',
  styleUrls: ['./installer-available-jobs.component.css'],
  providers: [InstallerService]
})
export class InstallerAvailableJobsComponent implements OnInit {

  pageLoading = true;
  loginUser:any;
  items = [];
  pageOfItems: Array<any>;
  message: string;
  alertClass: string;
  alert:string;
  constructor(
    private router: Router,
    private installerService: InstallerService,
    private chatService: ChatService
  ) {

  }

  ngOnInit() { 
    this.loginUser = JSON.parse (localStorage.getItem('installerUser'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
    this.pageLoading = true;
    this.installerService.availableJobListing(this.loginUser.userToken)
    .subscribe(
      res => {
        this.items = res['data'] || [];
        console.log("Response: ", res);
        if (res['success']) {
          this.alertClass = "alert-success";
          this.message = res['message'];
        } else {
          this.alert = "alert-danger";             
          this.message = res['message'];
        }
        this.pageLoading = false;
      },
      error => {
        console.log("Error: ", error);
        if (error.status === 401) {
          this.alert = "alert-danger";
          this.message = error.error['message'];
        }
        this.pageLoading = false;
      }
    );

    this.chatService.readChat(this.loginUser.userToken)
    .subscribe(
      res => {
        
      },
      error => {
        console.log("Error: ", error);         
      }
    );
}
onChangePage(pageOfItems: Array<any>) {
  // update current page of items
  this.pageOfItems = pageOfItems || [];
}
encode (str:any) {
  return btoa (str);
}

decode (str:any) {
  return atob (str);
}

}
