import { Component, OnInit } from '@angular/core';
import { InstallerService } from '../../../_services/installer/installer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-installer-progress-jobs',
  templateUrl: './installer-progress-jobs.component.html',
  styleUrls: ['./installer-progress-jobs.component.css'],
  providers: [InstallerService]
})
export class InstallerProgressJobsComponent implements OnInit {

  pageLoading = true;
  loginUser:any;
  items = [];
  pageOfItems: Array<any>;
  message: string;
  alertClass: string;
  alert:string;
  constructor(
    private router: Router,
    private installerService: InstallerService
  ) {

  }

  ngOnInit() { 
    this.loginUser = JSON.parse (localStorage.getItem('installerUser'));
    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
    this.pageLoading = true;
    this.installerService.progressJobLsiting(this.loginUser.userToken)
    .subscribe(
      res => {
        this.items = res['data'] || [];

        if (this.items.length > 0) {
          this.items.forEach (element => {
            element.jobIdD = this.encode(element.jobId);
          });
        }

        console.log("Response: ", res);
        if (res['success']) {
          this.alertClass = "alert-success";
          this.message = res['message'];
        } else {
          this.alert = "alert-danger";             
          this.message = res['message'];
        }
        this.pageLoading = false;
      },
      error => {
        console.log("Error: ", error);
        if (error.status === 401) {
          this.alert = "alert-danger";
          this.message = error.error['message'];
        }
        this.pageLoading = false;
      }
    );
}
onChangePage(pageOfItems: Array<any>) {
  // update current page of items
  this.pageOfItems = pageOfItems || [];
}

encode (str:any) {
  return btoa (str);
}

decode (str:any) {  
  return atob (str);
}


}
