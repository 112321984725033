import { Component, OnInit,HostListener } from '@angular/core';
import { FormBuilder, FormGroup,  Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatService } from '../../../_services/chat/chat.service';
import { InstallerService } from '../../../_services/installer/installer.service';
import { InstallerFormBuilderService } from '../../../_services/installer/installer-form-builder.service';

@Component({
  selector: 'app-installer-job-chat',
  templateUrl: './installer-job-chat.component.html',
  styleUrls: ['./installer-job-chat.component.css']
})
export class InstallerJobChatComponent implements OnInit {

  jobId:any;
  jobIdD:any;
  takeJobForm: FormGroup;
  formSubmitted = false;
  pageLoading = false;
  callLoading = false;
  loginUser:any;
  items:any;  
  chats:any;  
  chatId = 0;
  client= 0;
  installer= 0;

  message: string;
  alertClass: string;
  alert:string;
  msg: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder:FormBuilder,
    private chatService: ChatService,
    private installerService: InstallerService,
    private installerFormBuilderService: InstallerFormBuilderService
  ) { 
    this.jobIdD = this.route.snapshot.paramMap.get("jobId");
    this.jobId = atob(this.route.snapshot.paramMap.get("jobId"));
  }

  get f() { return this.takeJobForm.controls; }

  ngOnInit() {
    this.takeJobForm = this.installerFormBuilderService.takeJobFormBuilder();

    this.loginUser = JSON.parse (localStorage.getItem('installerUser'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
    this.pageLoading = true;
    this.installerService.viewJob(this.loginUser.userToken, this.jobId)
      .subscribe(
        res => {
          this.items = res['data'];
          if (!res['success']) {
            this.alert = "alert-danger";             
            this.message = res['message'];
          } 
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
 
      this.chatService.getChatId(this.loginUser.userToken, this.jobId)
      .subscribe(
        res => {
          const chatData = res['data'];
         
          if(chatData.customernumber != undefined){
            this.client = chatData.customernumber;
            console.log( this.client);
          }
          if(chatData.installernumber != undefined){
            this.installer = chatData.installernumber;
          }
          this.chatId = chatData.chatId;

          if (this.chatId > 0) {
            this.jobChatApi ();
            setInterval(() => {
              this.jobChatApi ();
            }, 7000);
          }
        },
        error => {
          console.log("Error: ", error);
        }
      );
  }

  jobChatApi () {
    this.chatService.jobChat(this.loginUser.userToken, this.chatId)
    .subscribe(
      res => {
        const chats = res['data'];
        this.getChatData (chats);
      },
      error => {
        console.log("Error: ", error);
      }
    );
  }

  getChatData (chats) {
    let tmpArr = [];
    let day = [];

    if (chats.length > 0) {
      chats.forEach(element => {
        if (tmpArr[element.chatDay] == undefined) {
          tmpArr[element.chatDay] = [];
          day[element.chatDay] = element.chatDay;
        }
  
        tmpArr[element.chatDay].push (element);
      });
    }    

    this.chats = tmpArr;
    console.log (this.chats);
  }
  oncall(client,installer){
    console.log(client);
    const reqData = {};
    reqData['client'] = client;
    reqData['installer'] = installer;
    this.callLoading = true;
    this.installerService.takecall(reqData,this.loginUser.userToken)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.alert = "alert-success";
            this.message = res['message'];
          } else {
            this.alert = "alert-danger";             
            this.message = res['message'];
          }
          this.callLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.callLoading = false;
        }
      );
    return false;
    

  }
  onSubmit() {
    this.formSubmitted = true;
   
    // stop here if form is invalid
    if (this.takeJobForm.invalid) {
      console.log(this.takeJobForm.controls);
      return;
    }
  
    const reqData = this.takeJobForm.value;
    reqData.jobId = this.jobId;
    this.pageLoading = true;
    this.installerService.takeJob(reqData,this.loginUser.userToken)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.alert = "alert-success";
            this.message = res['message'];
            this.onReset();
          } else {
            this.alert = "alert-danger";             
            this.message = res['message'];
          }
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
    return false;
  }

  onReset() {
    this.formSubmitted = false;
    this.takeJobForm.reset();
  }

  onSendMsg() {
    if (this.msg) {
      console.log (this);
      let msg = this.msg;
      let jobId = this.jobId;

      const d = new Date();
      let hours = d.getHours();
      let ampm = hours >= 12 ? 'PM' : 'AM';

      let sendChatData:any = {};
      sendChatData.msg = this.msg;
      sendChatData.jobId = this.jobId;
      sendChatData.chatId = this.chatId;
      sendChatData.receiver = this.items.receiver;
      sendChatData.isSender = true;
      // sendChatData.chatTime = d.getHours() + ":" + d.getMinutes() + ":" + d.getMinutes() + " " + ampm;

      console.log (sendChatData);
      this.msg = "";  

      if (this.chats['Today'] == undefined) {
        this.chats['Today'] = [];
      }
      this.chats['Today'].push (sendChatData);

      this.chatService.sendMsg(sendChatData,this.loginUser.userToken)
      .subscribe(
        res => {
          console.log("Response: ", res);      
        },
        error => {
          console.log("Error: ", error);
        }
      );
      return false;
    }

    
  }
  @HostListener('scroll', ["$event"])
  onWindowScroll(event: any) {
    // visible height + pixel scrolled >= total height 
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      console.log("End");
    }
  }


}
