// Angular Module
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material';

import { SharedModule } from '../_modules/shared.module';
import { UserRoutingModule } from '../_routes/user-routing.module';
import { UserComponent } from './user.component';

// User Layout
import { UserFooterComponent } from './layout/user-footer/user-footer.component';
import { UserHeaderComponent } from './layout/user-header/user-header.component';
import { UserSidebarComponent } from './layout/user-sidebar/user-sidebar.component';

// User Components
import { UserUpdatePasswordComponent } from './components/user-update-password/user-update-password.component';

// User Pages
import { AddAppointmentComponent } from './pages/add-appointment/add-appointment.component';
import { UserJobsComponent } from './pages/user-jobs/user-jobs.component';
import { JobRequestComponent } from './pages/job-request/job-request.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { ViewUserJobsComponent } from './pages/user-jobs/view-user-jobs/view-user-jobs.component';
import { CallcenterAccountingComponent } from './pages/callcenter-accounting/callcenter-accounting.component';
import { UserEditProfileComponent } from './components/user-edit-profile/user-edit-profile.component';
import { ViewCallcenterAccountingComponent } from './pages/callcenter-accounting/view-callcenter-accounting/view-callcenter-accounting.component';
import { EditUserJobsComponent } from './pages/user-jobs/edit-user-jobs/edit-user-jobs.component';
import { OngoingChatsComponent } from './pages/ongoing-chats/ongoing-chats.component';
import { PastChatsComponent } from './pages/past-chats/past-chats.component';
import { CallcenterJobChatComponent } from './pages/callcenter-job-chat/callcenter-job-chat.component';
import { UserDiponibilityComponent } from './pages/user-diponibility/user-diponibility.component';
import { InstallerListComponent } from './pages/installer-list/installer-list.component';
import { ViewInstallerComponent } from './pages/installer-list/view-installer/view-installer.component';

@NgModule({
  declarations: [
    UserComponent,
    UserFooterComponent, 
    UserHeaderComponent, 
    UserSidebarComponent,    
    UserUpdatePasswordComponent, 
    AddAppointmentComponent, 
    UserJobsComponent, 
    MyAccountComponent, 
    ViewUserJobsComponent,
    CallcenterAccountingComponent, 
    UserEditProfileComponent, 
    ViewCallcenterAccountingComponent, 
    EditUserJobsComponent, 
    OngoingChatsComponent, 
    JobRequestComponent,
    PastChatsComponent, 
    CallcenterJobChatComponent,
    UserDiponibilityComponent,
    InstallerListComponent,
    ViewInstallerComponent
  ],
  imports: [
    CommonModule,
    SharedModule,    
    UserRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class UserModule { }
