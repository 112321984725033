import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

// User Layout Component 
import { UserComponent } from '../user/user.component';
import { UserUpdatePasswordComponent } from '../user/components/user-update-password/user-update-password.component';
import { AddAppointmentComponent } from '../user/pages/add-appointment/add-appointment.component';
import { UserJobsComponent } from '../user/pages/user-jobs/user-jobs.component';
import { MyAccountComponent } from '../user/pages/my-account/my-account.component';
import { ViewUserJobsComponent } from '../user/pages/user-jobs/view-user-jobs/view-user-jobs.component';
import { CallcenterAccountingComponent } from '../user/pages/callcenter-accounting/callcenter-accounting.component';
import { UserEditProfileComponent } from '../user/components/user-edit-profile/user-edit-profile.component';
import { ViewCallcenterAccountingComponent } from '../user/pages/callcenter-accounting/view-callcenter-accounting/view-callcenter-accounting.component';
import { EditUserJobsComponent } from '../user/pages/user-jobs/edit-user-jobs/edit-user-jobs.component';
import { OngoingChatsComponent } from '../user/pages/ongoing-chats/ongoing-chats.component';
import { PastChatsComponent } from '../user/pages/past-chats/past-chats.component';
import { CallcenterJobChatComponent } from '../user/pages/callcenter-job-chat/callcenter-job-chat.component';
import { JobRequestComponent } from '../user/pages/job-request/job-request.component';
import { UserDiponibilityComponent } from '../user/pages/user-diponibility/user-diponibility.component';
import { InstallerListComponent } from '../user/pages/installer-list//installer-list.component';
import { ViewInstallerComponent } from '../user/pages/installer-list/view-installer/view-installer.component';
import { MyPostedAvailabilityComponent } from '../installer/pages/my-posted-availability/my-posted-availability.component';

const routes: Routes = [
  {
      path: '',
      component: UserComponent,
      children: [
        { path: '', component: MyAccountComponent },
        { path: 'user-diponibility', component: UserDiponibilityComponent },
        { path: 'user-edit-profile', component: UserEditProfileComponent },
        { path: 'my-posted-availability/:id', component: MyPostedAvailabilityComponent },
        { path: 'update-password', component: UserUpdatePasswordComponent },
        { path: 'add-appointment', component: AddAppointmentComponent },
        { path: 'jobs', component: UserJobsComponent },
        { path: 'view-job/:jobId', component: ViewUserJobsComponent },
        { path: 'edit-job/:jobId', component: EditUserJobsComponent },
        { path: 'my-account', component: MyAccountComponent },
        { path: 'caccounting', component: CallcenterAccountingComponent },
        { path: 'view-caccounting/:jobId', component: ViewCallcenterAccountingComponent },
        { path: 'ongoing-chat', component: OngoingChatsComponent },
        { path: 'past-chat', component: PastChatsComponent },
        { path: 'job-chat/:jobId', component: CallcenterJobChatComponent },
        {path: 'job-request/:jobId', component: JobRequestComponent},
        { path: 'installateur-availability', component: InstallerListComponent },
        { path: 'view-installer/:userId', component: ViewInstallerComponent },
      ]
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class UserRoutingModule { }
