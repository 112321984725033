import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { InstallerComponent } from '../installer/installer.component';

// Installer Components
import { InstallerMyAccountComponent } from '../installer/components/installer-my-account/installer-my-account.component';
import { EditInstallerMyAccountComponent } from '../installer/components/installer-my-account/edit-installer-my-account/edit-installer-my-account.component';
import { InstallerUpdatePasswordComponent } from '../installer/components/installer-update-password/installer-update-password.component';

// Installer Pages
import { InstallerPostADisponsibilityComponent } from '../installer/pages/installer-post-a-disponsibility/installer-post-a-disponsibility.component';
import { InstallerWarehouseComponent } from '../installer/pages/installer-warehouse/installer-warehouse.component';
import { InstallerEditWarehouseComponent } from '../installer/pages/installer-warehouse/installer-edit-warehouse/installer-edit-warehouse.component';
import { InstallerAddWarehouseComponent } from '../installer/pages/installer-warehouse/installer-add-warehouse/installer-add-warehouse.component';
import { InstallerAvailableJobsComponent } from '../installer/pages/installer-available-jobs/installer-available-jobs.component';
import { InstallerProgressJobsComponent } from '../installer/pages/installer-progress-jobs/installer-progress-jobs.component';
import { InstallerChatComponent } from '../installer/pages/installer-chat/installer-chat.component';
import { InstallerAccountingComponent } from '../installer/pages/installer-accounting/installer-accounting.component';
import { ViewInstallerAccountingComponent } from '../installer/pages/installer-accounting/view-installer-accounting/view-installer-accounting.component';
import { InstallerTakeJobComponent } from '../installer/pages/installer-take-job/installer-take-job.component';
import { InstallerJobChatComponent } from '../installer/pages/installer-job-chat/installer-job-chat.component';
import { MyPostedAvailabilityComponent } from '../installer/pages/my-posted-availability/my-posted-availability.component';
import { InstallerPostedAvailabilityListingComponent } from '../installer/pages/installer-posted-availability-listing/installer-posted-availability-listing.component';
import { EditInstallerPostedAvailabilityComponent } from '../installer/pages/installer-posted-availability-listing/edit-installer-posted-availability/edit-installer-posted-availability.component';

const routes: Routes = [
  {
      path: '',
      component: InstallerComponent,
      children: [
        { path: '', component: InstallerMyAccountComponent },
        { path: 'my-posted-availability-listing', component: InstallerPostedAvailabilityListingComponent },
        { path: 'my-posted-availability/:id', component: MyPostedAvailabilityComponent },
        { path: 'edit-my-posted-availability/:id', component: EditInstallerPostedAvailabilityComponent },
        { path: 'my-account', component: InstallerMyAccountComponent },
        { path: 'edit-my-account', component: EditInstallerMyAccountComponent },
        { path: 'update-password', component: InstallerUpdatePasswordComponent },
        { path: 'post-a-disponsibility', component: InstallerPostADisponsibilityComponent },        
        { path: 'warehouse', component: InstallerWarehouseComponent },
        { path: 'add-warehouse', component: InstallerAddWarehouseComponent },
        { path: 'edit-warehouse/:warehouseId', component: InstallerEditWarehouseComponent },        
        { path: 'available-jobs', component: InstallerAvailableJobsComponent },
        { path: 'progress-jobs', component: InstallerProgressJobsComponent },
        { path: 'take-job/:jobId', component: InstallerTakeJobComponent },
        { path: 'job-chat/:jobId', component: InstallerJobChatComponent },
        { path: 'chat', component: InstallerChatComponent },
        { path: 'accounting', component: InstallerAccountingComponent },
        { path: 'view-iaccounting/:jobId', component: ViewInstallerAccountingComponent },        
      ]
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class InstallerRoutingModule { }
