import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router} from '@angular/router';
import { Validators } from '@angular/forms';


import { HomeApiService } from '../../../_services/home/home-api.service';

import { UserFormBuilderService } from '../../../_services/user/user-form-builder.service';


@Component({
  selector: 'app-add-appointment',
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.css'],
  providers: [HomeApiService, UserFormBuilderService]
})
export class AddAppointmentComponent implements OnInit {

  addAppointmentForm: FormGroup;
  formSubmitted = false;
  pageLoading = false;
  loginUser:any;

  message: string;
  alertClass: string;
  alert:string;

  showRoofHeight = false;
  isAlreadyTrue = false;
  isExistingInsulation = false;
  isProductTrue = false;  
  uniqueNumber = this.getUniqueNumber();

  constructor(
    private router: Router,
    private homeApiService: HomeApiService,
    private userFormBuilderService: UserFormBuilderService
  ) { }
  
  ngOnInit() {
    this.addAppointmentForm = this.userFormBuilderService.addAppointmentForm();
    this.addAppointmentForm.patchValue({jobTitle: this.uniqueNumber});
    this.loginUser = JSON.parse (localStorage.getItem('appUser'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
  }

  existingInsulation (event:any) {

    if (this.f.existingInsulation.value == "yes") {
      this.isExistingInsulation = true;

      this.addAppointmentForm.get('typeOfExistingInsulation').setValidators([Validators.required]);
      this.addAppointmentForm.get('sinceWhen').setValidators([Validators.required]);
      this.addAppointmentForm.get('typeOfExistingInsulation').updateValueAndValidity();
      this.addAppointmentForm.get('sinceWhen').updateValueAndValidity();
    } else {
      this.isExistingInsulation = false;  

      this.addAppointmentForm.get('typeOfExistingInsulation').setValidators([]);
      this.addAppointmentForm.get('sinceWhen').setValidators([]);
      this.addAppointmentForm.get('typeOfExistingInsulation').updateValueAndValidity();
      this.addAppointmentForm.get('sinceWhen').updateValueAndValidity();
    }      
  }

  detuilleChange (event:any) {

    if (this.f.detuille.value == "yes") {
      this.showRoofHeight = true;

      this.addAppointmentForm.get('roofHeight').setValidators([Validators.required]);
      this.addAppointmentForm.get('roofHeight').updateValueAndValidity();
    } else {
      this.showRoofHeight = false;  

      this.addAppointmentForm.get('roofHeight').setValidators([]);
      this.addAppointmentForm.get('roofHeight').updateValueAndValidity();
    }      
  }

  alreadyTrue (event:any) {

    if (this.f.alreadyRefuse.value == "yes") {
      this.isAlreadyTrue = true;

      this.addAppointmentForm.get('reasonForRefusal').setValidators([Validators.required]);
      this.addAppointmentForm.get('reasonForRefusal').updateValueAndValidity();
    } else {
      this.isAlreadyTrue = false;  

      this.addAppointmentForm.get('reasonForRefusal').setValidators([]);
      this.addAppointmentForm.get('reasonForRefusal').updateValueAndValidity();
    }      
  }

  productTrue (event:any) {

    if (this.f.product.value == "yes") {
      this.isProductTrue = true;

      this.addAppointmentForm.get('product1').setValidators([Validators.required]);
      this.addAppointmentForm.get('product2').setValidators([Validators.required]);
      this.addAppointmentForm.get('product3').setValidators([Validators.required]);
      this.addAppointmentForm.get('product4').setValidators([Validators.required]);
      this.addAppointmentForm.get('product5').setValidators([]);
      this.addAppointmentForm.get('product1').updateValueAndValidity();
      this.addAppointmentForm.get('product2').updateValueAndValidity();
      this.addAppointmentForm.get('product3').updateValueAndValidity();
      this.addAppointmentForm.get('product4').updateValueAndValidity();
      this.addAppointmentForm.get('product5').updateValueAndValidity();
    } else {
      this.isProductTrue = false;  

      this.addAppointmentForm.get('product1').setValidators([]);
      this.addAppointmentForm.get('product2').setValidators([]);
      this.addAppointmentForm.get('product3').setValidators([]);
      this.addAppointmentForm.get('product4').setValidators([]);
      this.addAppointmentForm.get('product5').setValidators([]);
      this.addAppointmentForm.get('product1').updateValueAndValidity();
      this.addAppointmentForm.get('product2').updateValueAndValidity();
      this.addAppointmentForm.get('product3').updateValueAndValidity();
      this.addAppointmentForm.get('product4').updateValueAndValidity();
      this.addAppointmentForm.get('product5').updateValueAndValidity();
    }      
  }

  

  getUniqueNumber () {
    let now = new Date();    
    return now.getFullYear().toString() + "" + now.getMonth () + "" + now.getHours() + "" + now.getMinutes() + "" + now.getMilliseconds();
  }

  // convenience getter for easy access to form fields
  get f() { return this.addAppointmentForm.controls; }

  onSubmit() {
    this.formSubmitted = true;
   
    // stop here if form is invalid
    if (this.addAppointmentForm.invalid) {
      console.log(this.addAppointmentForm.controls);
      return;
    }
  
    const reqData = this.addAppointmentForm.value;
    reqData.userId = this.loginUser.userId;
    this.pageLoading = true;
    this.homeApiService.addAppointment(reqData,this.loginUser.userToken)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.alert = "alert-success";
            this.message = res['message'];
            this.onReset();
          } else {
            this.alert = "alert-danger";             
            this.message = res['message'];
          }
          this.pageLoading = false;

          this.router.navigate(['/user/jobs']);
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
    return false;
  }

  onReset() {
    this.formSubmitted = false;
    this.addAppointmentForm.reset();
  }

}
