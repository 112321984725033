import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatService } from '../../../_services/chat/chat.service';
import { InstallerService } from '../../../_services/installer/installer.service';
import { InstallerFormBuilderService } from '../../../_services/installer/installer-form-builder.service';

@Component({
  selector: 'app-admin-job-chat',
  templateUrl: './admin-job-chat.component.html',
  styleUrls: ['./admin-job-chat.component.css']
})
export class AdminJobChatComponent implements OnInit {

  jobId: any;
  jobIdD: any;
  takeJobForm: FormGroup;
  formSubmitted = false;
  pageLoading = false;
  loginUser: any;
  items: any;
  chats: any;
  chatId = 0;

  message: string;
  alertClass: string;
  alert: string;
  msg: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private chatService: ChatService,
    private installerService: InstallerService,
    private installerFormBuilderService: InstallerFormBuilderService
  ) {
    this.jobIdD = this.route.snapshot.paramMap.get("jobId");
    this.jobId = atob(this.route.snapshot.paramMap.get("jobId"));
  }

  get f() { return this.takeJobForm.controls; }

  ngOnInit() {

    this.loginUser = JSON.parse(localStorage.getItem('appAdmin'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }

    this.pageLoading = true;
    this.installerService.viewJob(this.loginUser.userToken, this.jobId)
      .subscribe(
        res => {
          this.items = res['data'];
          if (!res['success']) {
            this.alert = "alert-danger";
            this.message = res['message'];
          }
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );

    this.chatService.getChatIdForCallCenter(this.loginUser.userToken, this.jobId)
      .subscribe(
        res => {
          const chatData = res['data'];
          this.chatId = chatData.chatId;

          if (this.chatId > 0) {
            this.jobChatApi();
            setInterval(() => {
              this.jobChatApi ();
            }, 7000);
          }
        },
        error => {
          console.log("Error: ", error);
        }
      );
  }

  jobChatApi() {
    this.chatService.jobChat(this.loginUser.userToken, this.chatId)
      .subscribe(
        res => {
          const chats = res['data'];
          this.getChatData(chats);
        },
        error => {
          console.log("Error: ", error);
        }
      );
  }

  getChatData(chats) {
    let tmpArr = [];
    let day = [];

    if (chats.length > 0) {
      chats.forEach(element => {
        if (tmpArr[element.chatDay] == undefined) {
          tmpArr[element.chatDay] = [];
          day[element.chatDay] = element.chatDay;
        }

        tmpArr[element.chatDay].push(element);
      });
    }

    this.chats = tmpArr;
    console.log(this.chats);
  }
}
