import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HomeApiService } from '../../../_services/home/home-api.service';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.css']
})
export class UserHeaderComponent implements OnInit {
  userinfo: any

  public pushRightClass: string;
  public userLang: any;
  public userLangFlag: any;
  notificationCount = 0;
  loginUser:any;

  // menu-toggle
  visible: boolean = true;
  @Output() open: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    public router: Router,
    private homeApiService: HomeApiService
  ) {
    this.router.events.subscribe(val => {
      if (
        val instanceof NavigationEnd &&
        window.innerWidth <= 992 &&
        this.isToggled()
      ) {
        this.toggleSidebar();
      }
    });
  }

  getUserChatNotification () {
    this.loginUser = JSON.parse(localStorage.getItem('appUser')) || [];
    this.homeApiService.userChatNotifications(this.loginUser.userToken)
      .subscribe(
        res => {
          let notificationData = res['data'];    
          this.notificationCount =  (notificationData.length > 0) ? notificationData.length : 0; 
        },
        error => {
          console.log("Error: ", error);         
        }
      );
  }

  ngOnInit() {
    this.pushRightClass = 'push-right';
    this.userinfo = JSON.parse(localStorage.getItem('appUser')) || [];

    this.getUserChatNotification(); 
    setInterval(() => {
      this.getUserChatNotification(); 
    }, 8000);
  }

  // menu-toggle
  toggle() {
    this.visible = !this.visible;
    if (this.visible) {
      this.open.emit(null); //emit event here
    } else {
      this.close.emit(null);
    }
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  onLoggedout() {
    localStorage.removeItem('appUser');
    this.router.navigate(['/login']);
  }

}
