import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../_services/admin/admin.service';

@Component({
  selector: 'app-admin-installer-list',
  templateUrl: './admin-installer-list.component.html',
  styleUrls: ['./admin-installer-list.component.css'],
  providers: [AdminService]
})
export class AdminInstallerListComponent implements OnInit {
  pageLoading = true;
  buttonLoading = false;
  loginUser:any;
  items = [];
  pageOfItems: Array<any>;
  message: string;
  alertClass: string;
  alert:string;
  constructor(
    private router: Router,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.loginUser = JSON.parse (localStorage.getItem('appAdmin'));
    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
    this.pageLoading = true;
    this.adminService.installerList(this.loginUser.userToken)
    .subscribe(
      res => {
        this.items = res['data'] || [];
        console.log("Response: ", this.items);
        if (res['success']) {
          this.alertClass = "alert-success";
          this.message = res['message'];
        } else {
          this.alert = "alert-danger";             
          this.message = res['message'];
        }
        this.pageLoading = false;
      },
      error => {
        console.log("Error: ", error);
        if (error.status === 401) {
          this.alert = "alert-danger";
          this.message = error.error['message'];
        }
        this.pageLoading = false;
      }
    );
}
onStatusChange(status,item) {
  if(status == '1'){
    var msg = "Are you sure you want to verify this user.";
  }else{
    var msg = "Are you sure you want to rejected this user.";
  }
 
  if(confirm(msg)){
    const reqData = {};
    reqData['is_user_verify'] = status;
    reqData['userId'] = item.userId;
    item.buttonLoading = true;
    this.adminService.changeuserStatus(reqData)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.alert = "alert-success";
            this.message = res['message'];
            item.is_user_verify = status;
          } else {
            this.alert = "alert-danger";             
            this.message = res['message'];
          }
          item.buttonLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          item.buttonLoading = false;
        }
      );
  }
  return false;
}
onChangePage(pageOfItems: Array<any>) {
  // update current page of items
  this.pageOfItems = pageOfItems || [];
}
encode (str:any) {
  return btoa (str);
}

decode (str:any) {
  return atob (str);
}

}