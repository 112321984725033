import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeApiService } from '../../../_services/home/home-api.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-installer-my-account',
  templateUrl: './installer-my-account.component.html',
  styleUrls: ['./installer-my-account.component.css']
})
export class InstallerMyAccountComponent implements OnInit {

  jobId: any;
  pageLoading = true;
  loginUser: any;
  items:any;
  message: string;
  alertClass: string;
  alert: string;

  // Chart Variables
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private homeApiService: HomeApiService
  ) {
    this.jobId = atob(this.route.snapshot.paramMap.get("jobId"));
  }

  ngOnInit() {
    this.loginUser = JSON.parse(localStorage.getItem('installerUser'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }

    this.pageLoading = true;
    this.homeApiService.myAccount(this.loginUser.userToken)
      .subscribe(
        res => {
          this.items = res['data'];
          console.log("Response: ", res);

          if (!res['success']) {
            this.alert = "alert-danger";
            this.message = res['message'];
          }
          this.chatData(this.items, 2020);
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
  }

  yearChange(year: any) {
    this.chatData(this.items, year);
  }

  chatData(items: any, year: any) {
    if (items.userJobs.jobData[year]) {

      let labels = Object.keys(items.userJobs.jobData[year]);
      let data = Object.values(items.userJobs.jobData[year]);

      this.barChartLabels = labels;
      this.barChartData = [
        { data: data, label: ' Job' }
      ];

    } else {
      this.barChartLabels = [];
      this.barChartData = [
        { data: [], label: ' Job' }
      ];
    }
  }

}
