import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../../_services/home/auth.service';
import { HomeFormBuilderService } from '../../../_services/home/home-form-builder.service';

@Component({
  selector: 'app-home-forgot-password',
  templateUrl: './home-forgot-password.component.html',
  styleUrls: ['./home-forgot-password.component.css'],
  providers: [AuthService, HomeFormBuilderService]
})
export class HomeForgotPasswordComponent implements OnInit {

  forgotForm: FormGroup;
  formSubmitted = false;
  pageLoading = false;

  message: string;
  alert: string;

  constructor(
    private authService: AuthService,
    private homeFormBuilderService: HomeFormBuilderService
  ) { }

  ngOnInit() {
    this.forgotForm = this.homeFormBuilderService.forgotFormBuilder();
  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotForm.controls; }

  onSubmit() {
    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.forgotForm.invalid) {
      return;
    }

    const reqData = this.forgotForm.value;
    this.pageLoading = true;

    this.authService.forgot_password(reqData)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.onReset();
            this.alert = "alert-success";
            this.message = res['message'];
          } else {
            this.alert = "alert-danger";
            this.message = res['message'];
          }
          this.pageLoading = false;
        },
        error => {
          console.log(error);

          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
    return false;
  }

  onReset() {
    this.formSubmitted = false;
    this.forgotForm.reset();
  }

}
