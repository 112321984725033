import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { AuthService } from '../../../_services/home/auth.service';
import { HomeFormBuilderService } from '../../../_services/home/home-form-builder.service';

@Component({
  selector: 'app-call-center-signup',
  templateUrl: './call-center-signup.component.html',
  styleUrls: ['./call-center-signup.component.css'],
  providers: [AuthService, HomeFormBuilderService]
})
export class CallCenterSignupComponent implements OnInit {

  signUpForm: FormGroup;
  formSubmitted = false;
  pageLoading = false;

  message: string;
  alertClass: string;

  constructor(
    public router: Router,
    private authService: AuthService,
    private homeFormBuilderService: HomeFormBuilderService
  ) { }

  ngOnInit() {
    this.signUpForm = this.homeFormBuilderService.createFormBuilder();
  }

  // convenience getter for easy access to form fields
  get f() { return this.signUpForm.controls; }

  onSubmit() {
    
    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.signUpForm.invalid) {
     console.log(this.signUpForm.controls);
      return;
    }

    const reqData = this.signUpForm.value;
    reqData.userType = '1';
    
    this.pageLoading = true;
    this.authService.create(reqData)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.onReset();
            this.alertClass = "alert-success";
            this.message    = res['message'];

            this.router.navigate(['/signup-success']);            
            
          } else {
            this.alertClass = "alert-danger";
            this.message    = res['message'];

          }
          this.pageLoading = false;
        },
        error => {
          console.log ("Error: ", error);
          if (error.status === 401) {
            this.alertClass = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
    return false;
  }

  onReset() {
    this.formSubmitted = false;
    this.signUpForm.reset();
  }

}
