import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../../_services/home/auth.service';
import { HomeFormBuilderService } from '../../../_services/home/home-form-builder.service';

@Component({
  selector: 'app-home-login',
  templateUrl: './home-login.component.html',
  styleUrls: ['./home-login.component.css'],
  providers: [AuthService, HomeFormBuilderService]
})
export class HomeLoginComponent implements OnInit {

  loginForm: FormGroup;
  formSubmitted = false;
  pageLoading   = false;

  message:string;
  alert:string;

  constructor(
    public router: Router,
    private authService: AuthService,
    private homeFormBuilderService: HomeFormBuilderService
  ) { }

  ngOnInit() {
    this.loginForm = this.homeFormBuilderService.loginFormBuilder (); 
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.formSubmitted = true;    
    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    const reqData  = this.loginForm.value;
    const postData = {
        "name": reqData.name,
        "email": reqData.email,
        "password": reqData.password,
        "c_password": reqData.c_password
    };

    this.pageLoading = true;    

    this.authService.login(postData)
            .subscribe(
                res => {
                  console.log ("Response: ", res);
                  if (res['success']) {
                      const userInfo = res['data'];                       
                      this.onReset();   
                      this.alert = "alert-success";                   
                      this.message = res['message'];

                      localStorage.removeItem ('appUser');
                      localStorage.removeItem ('installerUser');
                      localStorage.removeItem ('appAdmin');

                      if (userInfo['userType'] == 1) {
                        localStorage.setItem('appUser', JSON.stringify(res['data']));
                        this.router.navigate(['/user/add-appointment']);
                      } else if (userInfo['userType'] == 2) {
                        localStorage.setItem('installerUser', JSON.stringify(res['data']));
                        this.router.navigate(['/installer/available-jobs']);
                      } else if(userInfo['userType'] == 3){
                        localStorage.setItem('appAdmin', JSON.stringify(res['data']));
                        this.router.navigate(['/admin/dashboard/']);
                      } else {
                        this.alert = "alert-danger";             
                        this.message = "Server is not responding with these login details."
                      }
                      
                  } else {     
                      this.alert = "alert-danger";             
                      this.message = res['message'];
                  }
                  this.pageLoading = false;
                },
                error => {
                  console.log (error);
                  
                  if (error.status === 401) {
                    this.alert = "alert-danger";             
                    this.message = error.error['message'];
                  }                   
                  this.pageLoading = false;
                }
            );    
    return false;
}

onReset() {
    this.formSubmitted = false;
    this.loginForm.reset();
}

}
