import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Application Modules
import { ToastrModule } from 'ngx-toastr';
import { LanguageTranslationModule } from '../_modules/language-translation.module'
import { TranslateModule } from '@ngx-translate/core';

// Home Components
import { HomeSidebarComponent } from './layout/home-sidebar/home-sidebar.component';
import { HomeLoginComponent } from './components/home-login/home-login.component';
import { HomeSignupComponent } from './components/home-signup/home-signup.component';
import { HomeForgotPasswordComponent } from './components/home-forgot-password/home-forgot-password.component';
import { HomeResetPasswordComponent } from './components/home-reset-password/home-reset-password.component';
import { SharedModule } from '../_modules/shared.module';
import { SignUpLinkComponent } from './layout/sign-up-link/sign-up-link.component';
import { CallCenterSignupComponent } from './components/call-center-signup/call-center-signup.component';
import { InstallerSignupComponent } from './components/installer-signup/installer-signup.component';
import { HomeSignUpSuccessComponent } from './components/home-signup/home-sign-up-success/home-sign-up-success.component';
import { HomeEmailVerificationComponent } from './components/home-email-verification/home-email-verification.component';


@NgModule({
  declarations: [
    HomeSidebarComponent, 
    HomeLoginComponent, 
    HomeSignupComponent, 
    HomeForgotPasswordComponent,
    HomeResetPasswordComponent,
    SignUpLinkComponent,
    CallCenterSignupComponent,
    InstallerSignupComponent,
    HomeSignUpSuccessComponent,
    HomeEmailVerificationComponent
  ],
  imports: [
    CommonModule,
    SharedModule, 
    BrowserModule, 
    BrowserAnimationsModule,    
    FormsModule,
    ReactiveFormsModule,     
    HttpClientModule, 
    RouterModule.forRoot([]),
    LanguageTranslationModule,
    TranslateModule,
    ToastrModule.forRoot()
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class HomeModule { }
