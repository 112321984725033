// Angular Module
import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from "@angular/common/http";

import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

import { ErrorHandlingService } from '../error-handling.service'

@Injectable({
  providedIn: 'root'
})
export class RemunerationService {

  url: any = environment.apiUrl;

  // APIs
  remunerationList = this.url + '/api/remuneration';
  
  // Login User
  loginUser = JSON.parse (localStorage.getItem('appUser')) || JSON.parse (localStorage.getItem('appAdmin')) || {};  
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.loginUser.userToken
  });

  constructor(
    public errorHandlingService: ErrorHandlingService,
    public httpClient: HttpClient
  ) { }

  // verifyEmail Request 
  remuneration() {
    return this.httpClient.get(this.remunerationList)
      .pipe(
        map(response => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  
}
