import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-success-error-message',
  templateUrl: './success-error-message.component.html',
  styleUrls: ['./success-error-message.component.css']
})
export class SuccessErrorMessageComponent implements OnInit {

  @Input('alertClass') alertClass: string;
  @Input('message') message: string;

  constructor() { }

  ngOnInit() {
  }
}
