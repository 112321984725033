import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../../../_services/admin/admin.service';
import { HomeFormBuilderService } from '../../../../_services/home/home-form-builder.service';

@Component({
  selector: 'app-admin-edit-call-center',
  templateUrl: './admin-edit-call-center.component.html',
  styleUrls: ['./admin-edit-call-center.component.css'],
  providers: [AdminService, HomeFormBuilderService]
})

export class AdminEditCallCenterComponent implements OnInit {
  userId:any;
  companyName: any;
  nameOfTheContact: any;
  telephone: any;
  address: any;
  city: any;
  pays: any;
  tradeRegisterNumber: any;
  pseudo: any;
  pinCode: any;

  jobId: any;

  pageLoading = false;
  getItemPageLoading = true;
  loginUser: any;
  items = [];
  message: string;
  alertClass: string;
  alert: string;

  editProfileForm: FormGroup;
  formSubmitted = false;
  filedata: any;
  fileEvent(e) {
    this.filedata = e.target.files[0];
  }
  constructor(
    private adminService: AdminService,
    private homeFormBuilderService: HomeFormBuilderService,
    private route: ActivatedRoute,
    private router: Router
  ) {
   
    this.userId = atob (this.route.snapshot.paramMap.get("userId"));
    
  }

  ngOnInit() {
    this.editProfileForm = this.homeFormBuilderService.editProfileFormBuilder();
    this.loginUser = JSON.parse(localStorage.getItem('appAdmin'));
    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }

    this.getItemInfo();
  }


  // convenience getter for easy access to form fields
  get f() { return this.editProfileForm.controls; }

  onSubmit() {

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.editProfileForm.invalid) {
      console.log(this.editProfileForm.controls);
      return;
    }

    if (this.filedata) {
      let promise = this.convertImageToBase64(this.filedata);
      promise.then((res) => {
        this.saveProfile(res);
      });
      promise.catch((err) => {
        console.log(err);
      });
    } else {
      this.saveProfile(false);
    }
  }

  saveProfile(res: any) {
    const reqData = this.editProfileForm.value;

    if (res) {
      reqData.img = res;
    }

    this.pageLoading = true;
    this.adminService.editInstallerProfile(reqData,this.userId)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.alertClass = "alert-success";
            this.message = res['message'];
          } else {
            this.alertClass = "alert-danger";
            this.message = res['message'];
          }
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alertClass = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
    return false;
  }


  convertImageToBase64(file: any) {
    if (file) {
      let promise = new Promise((resolve, reject) => {
        var mimeType = file.type;

        if (mimeType.match(/image\/*/) == null) {
          return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (_event) => {
          resolve(reader.result);
        }
      });

      return promise;
    }

  }

  onReset() {
    this.formSubmitted = false;
    //this.editProfileForm.reset();
  }

  getItemInfo() {
    this.adminService.userDetail(this.loginUser.userToken,this.userId)
      .subscribe(
        res => {
          const itemINfo = res['data'];
          if (res['success']) {
            let formData: any = [];
            formData.companyName = itemINfo.companyName || "";
            formData.nameOfTheContact = itemINfo.contactName || "";
            formData.telephone = itemINfo.telephone || "";
            formData.address = itemINfo.address || "";
            formData.city = itemINfo.userCity || "";
            formData.pays = itemINfo.pays || "";
            formData.tradeRegisterNumber = itemINfo.tradeRegisterNumber || "";
            formData.pseudo = itemINfo.pseudo || "";
            formData.pinCode = itemINfo.pinCode || "";
            formData.remuneration = itemINfo.remuneration || "";
            formData.ifscCode = itemINfo.ifscCode || "";
            formData.bankName = itemINfo.bankName || "";
            formData.accountNumber = itemINfo.accountNumber || "";
            formData.accountHolderName = itemINfo.accountHolderName || "";
            this.editProfileForm.patchValue(formData);
          } else {
            this.alert = "alert-danger";
            this.message = res['message'];
          }
          this.getItemPageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.getItemPageLoading = false;
        }
      );
  }
}
