import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router} from '@angular/router';
import { InstallerService } from '../../../../_services/installer/installer.service';

import { InstallerFormBuilderService } from '../../../../_services/installer/installer-form-builder.service';


@Component({
  selector: 'app-installer-add-warehouse',
  templateUrl: './installer-add-warehouse.component.html',
  styleUrls: ['./installer-add-warehouse.component.css'],
  providers: [InstallerService, InstallerFormBuilderService]
})
export class InstallerAddWarehouseComponent implements OnInit {
  addwarehousForm: FormGroup;
  formSubmitted = false;
  pageLoading = false;
  loginUser:any;
  message: string;
  alertClass: string;
  alert:string;

  constructor(
    private router: Router,
    private formBuilder:FormBuilder,
   
    private installerService: InstallerService,
    private installerFormBuilderService: InstallerFormBuilderService
  ) { }

  ngOnInit() {
    this.addwarehousForm = this.installerFormBuilderService.addWarehouseFormBuilder();

    this.loginUser = JSON.parse (localStorage.getItem('installerUser'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }

  }
  get warehouseDepartments() {
    return this.addwarehousForm.get('warehouseDepartments') as FormArray;
  }
  addwarehouseDepartment() {
    this.warehouseDepartments.push(this.formBuilder.group({
      dept: ['', [Validators.required]]
     
    }));
  }
  deletewarehouseDepartment(index) {
    if (this.warehouseDepartments.length > 1)
      this.warehouseDepartments.removeAt(index);
    else 
      alert ("One Department on is Required");
  }
   // convenience getter for easy access to form fields
   get f() { return this.addwarehousForm.controls; }

   onSubmit() {
     this.formSubmitted = true;
    
     // stop here if form is invalid
     if (this.addwarehousForm.invalid) {
       console.log(this.addwarehousForm.controls);
       return;
     }
   
     const reqData = this.addwarehousForm.value;
     console.log(reqData);
     reqData.installerId = this.loginUser.userId;
     this.pageLoading = true;
     this.installerService.addWarehouse(reqData,this.loginUser.userToken)
       .subscribe(
         res => {
           console.log("Response: ", res);
           if (res['success']) {
             this.alert = "alert-success";
             this.message = res['message'];
             this.onReset();
           } else {
             this.alert = "alert-danger";             
             this.message = res['message'];
           }
           this.pageLoading = false;
         },
         error => {
           console.log("Error: ", error);
           if (error.status === 401) {
             this.alert = "alert-danger";
             this.message = error.error['message'];
           }
           this.pageLoading = false;
         }
       );
     return false;
   }
 
   onReset() {
     this.formSubmitted = false;
     this.addwarehousForm.reset();
   }

}
