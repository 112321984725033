import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../_services/admin/admin.service';
import { ModalService } from '../../../_modal';
@Component({
  selector: 'app-admin-job-payment-request',
  templateUrl: './admin-job-payment-request.component.html',
  styleUrls: ['./admin-job-payment-request.component.css'],
  providers: [AdminService]
})
export class AdminJobPaymentRequestComponent implements OnInit {
  pageLoading = true;
  loginUser:any;
  items = [];
  paymentData:any;
  getpaymentData =false;
  pageOfItems: Array<any>;
  message: string;
  alertClass: string;
  alert:string;
  constructor(
    private router: Router,
    private modalService: ModalService,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.loginUser = JSON.parse (localStorage.getItem('appAdmin'));
    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
    this.pageLoading = true;
    this.adminService.jobPaymentRequest()
    .subscribe(
      res => {
        this.items = res['data'] || [];
        console.log("Response: ", this.items);
        if (res['success']) {
          this.alertClass = "alert-success";
          this.message = res['message'];
        } else {
          this.alert = "alert-danger";             
          this.message = res['message'];
        }
        this.pageLoading = false;
      },
      error => {
        console.log("Error: ", error);
        if (error.status === 401) {
          this.alert = "alert-danger";
          this.message = error.error['message'];
        }
        this.pageLoading = false;
      }
    );
}
onReleasePayment(item,id){
  this.getpaymentData = true;
  this.paymentData = item;
  this.modalService.open('payment');
}
closeModal() {
  this.getpaymentData =false;
  this.modalService.close('payment');
  this.paymentData = {};
}

onpaymentDone(item) {
  if(confirm('Are you sure you want to update payment status.')){
    const reqData = {};
    reqData['paymentId'] = item.paymentId;
    item.buttonLoading = true;
    this.adminService.paymentDone(reqData)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.closeModal();
            this.getpaymentData =false;
            this.paymentData = {};
            this.alert = "alert-success";
            this.message = res['message'];
            item.paymentStatus = (item.paymentStatus == 1) ? 0: 1;
          } else {
            this.alert = "alert-danger";             
            this.message = res['message'];
          }
          item.buttonLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          item.buttonLoading = false;
        }
      );
  }
  return false;
}
onChangePage(pageOfItems: Array<any>) {
  // update current page of items
  this.pageOfItems = pageOfItems || [];
}
encode (str:any) {
  return btoa (str);
}

decode (str:any) {
  return atob (str);
}


}
