import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from "@angular/common/http";

import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

import { ErrorHandlingService } from '../error-handling.service'

@Injectable()
export class AuthService {
  url: any = environment.apiUrl;

  // APIs
  loginApi = this.url + '/api/login';
  signUpApi = this.url + '/api/register';
  forgotPasswordApi = this.url + '/api/forgot_password';
  ResetUrlApi = this.url + '/api/find/';
  resetPasswordApi = this.url + '/api/reset';
  updatePasswordApi = this.url + '/api/user-update-password';
  editProfileApi = this.url + '/api/user-edit-profile';
  verifyEmailApi = this.url + '/api/verify-email/';

  constructor(
    public errorHandlingService: ErrorHandlingService,
    public httpClient: HttpClient
  ) { }

  // Sign up Request 
  create(reqData: any) {
    return this.httpClient.post(this.signUpApi, reqData)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Login Request 
  login(reqData: any) {
    return this.httpClient.post(this.loginApi, reqData)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  } 

  // Forgot Password Request 
  forgot_password(reqData:any) {
    return this.httpClient.post(this.forgotPasswordApi, reqData)
      .pipe(
        map(response => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Reset Url Request 
  reset_url(id:any) {
    return this.httpClient.get(this.ResetUrlApi + id)
      .pipe(
        map(response => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  //Reset Password 
  reset_password(reqData:any) {
    return this.httpClient.post(this.resetPasswordApi, reqData)
      .pipe(
        map(response => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }


  // Update Password 
  updatePassword(reqData: any,userToken) {  
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });

      const options = { headers: headers };
      return this.httpClient.post(this.updatePasswordApi,reqData, options)
        .pipe(
          map((response:Response) => { return response;}),
          catchError(error => this.errorHandlingService.handleError(error))
        );
  }

  installerupdatePassword(reqData: any) {    
    let loginUser = JSON.parse(localStorage.getItem('installerUser'));
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + loginUser.userToken
    });

    const options = { headers: headers };
    return this.httpClient.post(this.updatePasswordApi,reqData, options)
      .pipe(
        map((response:Response) => { return response;}),
        catchError(error => this.errorHandlingService.handleError(error))
      );
}

  // Edit Profile 
  editProfile(reqData: any) {
    let loginUser = JSON.parse(localStorage.getItem('appUser'));
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + loginUser.userToken
    });

    const options = { headers: headers };
    return this.httpClient.post(this.editProfileApi,reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  

  editInstallerProfile(reqData: any) {

    let loginUser = JSON.parse(localStorage.getItem('installerUser'));
  
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + loginUser.userToken
    });
    const options = { headers: headers };
    return this.httpClient.post(this.editProfileApi,reqData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // verifyEmail Request 
  verifyEmail(id:any) {
    return this.httpClient.get(this.verifyEmailApi + id)
      .pipe(
        map(response => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

}
