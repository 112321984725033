import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  collapedSideBar: boolean;
  public userLang:any;
  public userLangFlag:any;

  menuToggle = false;

  constructor() {
        
  }

  onOpen (e) {
      this.menuToggle = false;
  }

  onClose (e) {
      this.menuToggle = true;
  }

  ngOnInit() {}

  receiveCollapsed($event) {
      this.collapedSideBar = $event;
      this.menuToggle = !this.menuToggle;
  }

}
