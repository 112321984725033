import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

import { ErrorHandlingService } from '../error-handling.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  // APIs
  url: any = environment.apiUrl;
  getChatIdApi = this.url + '/api/getChatId';
  getChatIdForCallCenterApi = this.url + '/api/getChatIdForCallCenter';
  getjoChat = this.url + '/api/jobchat';
  sendchat = this.url + '/api/chat';
  callCenterChatsApi = this.url + '/api/callCenterChats';
  adminChatsApi = this.url + '/api/adminChats';
  readChatApi = this.url + '/api/readNotification';
  
  constructor(
    public errorHandlingService: ErrorHandlingService,
    public httpClient: HttpClient
  ) { }

  getHeader (userToken:any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });

    return headers;
  }

  readChat (userToken) {
    const headers = this.getHeader (userToken);
    const options = { headers: headers };

    return this.httpClient.get(this.readChatApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Job Chat Id
  getChatId(userToken, jobId: any) {
    const headers = this.getHeader (userToken);
    const options = { headers: headers };

    return this.httpClient.get(this.getChatIdApi + '/' + jobId, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Job Chat Id
  getChatIdForCallCenter(userToken, jobId: any) {
    const headers = this.getHeader (userToken);
    const options = { headers: headers };

    return this.httpClient.get(this.getChatIdForCallCenterApi + '/' + jobId, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Job Chat
  jobChat(userToken, jobId: any) {
    const headers = this.getHeader (userToken);
    const options = { headers: headers };

    return this.httpClient.get(this.getjoChat + '/' + jobId, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Send Message
  sendMsg(sendChatData: any, userToken) {
    let loginUser = JSON.parse(localStorage.getItem('appUser')) || JSON.parse(localStorage.getItem('installerUser'));

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken
    });
    const options = { headers: headers };
    console.log (sendChatData);
    sendChatData.userType = loginUser.userType;
    return this.httpClient.post(this.sendchat, sendChatData, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  
  // Job Chat
  callCenterChats(userToken) {
    const headers = this.getHeader (userToken);
    const options = { headers: headers };

    return this.httpClient.get(this.callCenterChatsApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }

  // Admin Chat
  adminChats(userToken) {
    const headers = this.getHeader (userToken);
    const options = { headers: headers };

    return this.httpClient.get(this.adminChatsApi, options)
      .pipe(
        map((response: Response) => { return response; }),
        catchError(error => this.errorHandlingService.handleError(error))
      );
  }
  
  
}
