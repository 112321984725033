import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  collapedSideBar: boolean;
    public userLang:any;
    public userLangFlag:any;

    menuToggle = false;

    constructor() {
    	    
    }

    onOpen (e) {
        this.menuToggle = false;
    }

    onClose (e) {
        this.menuToggle = true;
    }

    ngOnInit() {}

    receiveCollapsed($event) {
        this.collapedSideBar = $event;
		this.menuToggle = !this.menuToggle;
    }

}
