import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../../_services/home/auth.service';
import { HomeFormBuilderService } from '../../../_services/home/home-form-builder.service';

@Component({
  selector: 'app-user-update-password',
  templateUrl: './user-update-password.component.html',
  styleUrls: ['./user-update-password.component.css'],
  providers: [AuthService, HomeFormBuilderService]
})
export class UserUpdatePasswordComponent implements OnInit {

  updatePasswordForm: FormGroup;
  formSubmitted = false;
  pageLoading = false;

  message: string;
  alert: string;
  loginUser = JSON.parse (localStorage.getItem('appAdmin')) || {};  

  constructor(
    public router: Router,
    private authService: AuthService,
    private homeFormBuilderService: HomeFormBuilderService
  ) { }

  ngOnInit() {
    this.updatePasswordForm = this.homeFormBuilderService.updatePasswordFormBuilder();
  }

  // convenience getter for easy access to form fields
  get f() { return this.updatePasswordForm.controls; }

  onSubmit() {
    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.updatePasswordForm.invalid) {
      return;
    }

    const reqData = this.updatePasswordForm.value;    
    this.pageLoading = true;
    this.authService.updatePassword(reqData,this.loginUser.userToken)
      .subscribe(
        res => {

          if (res['success']) {
            this.alert = "alert-success";
            this.message = res['message'];

            this.onReset();     
          } else {
            this.alert = "alert-danger";
            this.message = res['message'];
          }
          this.pageLoading = false;
        },
        error => {
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
    return false;
  }

  onReset() {
    this.formSubmitted = false;
    this.updatePasswordForm.reset();
  }

}
