import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { HomeApiService } from '../../../../_services/home/home-api.service';
import { UserFormBuilderService } from '../../../../_services/user/user-form-builder.service';

@Component({
  selector: 'app-edit-installer-posted-availability',
  templateUrl: './edit-installer-posted-availability.component.html',
  styleUrls: ['./edit-installer-posted-availability.component.css']
})
export class EditInstallerPostedAvailabilityComponent implements OnInit {

  public multiSelection: boolean = true;
  public dates: Date[];

  addAppointmentForm: FormGroup;
  showRoofHeight = true;
  formSubmitted: any;

  jobId:any;
  warehouseList:any;
  pageLoading = false;
  getPageLoading = false;
  loginUser:any;
  items:any;  
  message: string;
  alertClass: string;
  alert:string;
  installerAvailabilityId:any;
  isAlreadyTrue = false;
  isExistingInsulation = false;

  isMorning   = false;
  isAfterNoon = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private homeApiService: HomeApiService,
    private userFormBuilderService: UserFormBuilderService
  ) { 
    this.installerAvailabilityId = atob(this.route.snapshot.paramMap.get("id"));
    console.log ("installerAvailabilityId", this.installerAvailabilityId);
  }

  ngOnInit() {
    // this.dates = [new Date("2020/01/10"), new Date("2020/01/14"), new Date("2020/01/18")];
    this.loginUser = JSON.parse(localStorage.getItem('installerUser'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }

    this.addAppointmentForm = this.formBuilder.group({
      morning: [''],
      afterNoon: [''],
      warehouses: ['', [Validators.required]],
      rampant: ['', [Validators.required]],
      detuille: ['', [Validators.required]],
      maximumRoofHeight: ['', [Validators.required]],
      crawlSpace: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      insulationPossibleId: ['', [Validators.required]]
    });

    this.getPageLoading = true;


    this.homeApiService.getInstallerAvailability(this.installerAvailabilityId)
      .subscribe(
        res => {
          this.items = res['data']['detail'];
          this.warehouseList = res['data']['wharehouselist'];
          if(this.items != null){
            this.isMorning   = (this.items.morning == 1) ? true: false;
            this.isAfterNoon = (this.items.afterNoon == 1) ? true: false;

          }
          if ( this.items != null && this.items.availabilityDates != undefined) {
            let availDates = this.items.availabilityDates.split(",");
            if (availDates.length > 0) {
              var output = new Array();
              availDates.forEach(element => {
                output.push (new Date(element));
              });
              this.dates = output;
            }
          }
          if(this.items != null && this.items.detuille != undefined && this.items.detuille != null){
            this.detuilleChange(this.items.detuille);
          }else{
            this.detuilleChange('');
          }
          if(this.items != null){
            this.addAppointmentForm.patchValue(this.items);
          }
         
          if (this.items != null && this.items.detuille != undefined && this.items.detuille == 1) {
            this.showRoofHeight = true;
          }

          if (!res['success']) {
            this.alert = "alert-danger";             
            this.message = res['message'];
          } 
          this.getPageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.getPageLoading = false;
        }
      );
  }

  // convenience getter for easy access to form fields
  get f() { return this.addAppointmentForm.controls; }

  onValueChange(event): void {
    this.dates = event.values;
    console.log(this.dates);
  }

  detuilleChange(event: any) {
    console.log(this.f.detuille.value);

    if (this.f.detuille.value == "1") {
      this.showRoofHeight = true;

      this.addAppointmentForm.get('maximumRoofHeight').setValidators([Validators.required]);
      this.addAppointmentForm.get('maximumRoofHeight').updateValueAndValidity();
    } else {
      this.showRoofHeight = false;

      this.addAppointmentForm.get('maximumRoofHeight').setValidators([]);
      this.addAppointmentForm.get('maximumRoofHeight').updateValueAndValidity();
    }
  }

  onSubmit() {
    this.formSubmitted = true;

    // stop here if form is invalid
    if (this.addAppointmentForm.invalid) {
      console.log(this.addAppointmentForm.controls);
      return;
    }

    const reqData = this.addAppointmentForm.value;
    reqData.morning = reqData.morning || 0;
    reqData.afterNoon = reqData.afterNoon || 0;
    reqData.dates = this.dates;
    reqData.installerAvailabilityId = this.installerAvailabilityId;

    console.log(reqData);

    this.pageLoading = true;
    this.homeApiService.postADisponsibilityData(reqData, this.loginUser.userToken)
      .subscribe(
        res => {
          console.log("Response: ", res);
          this.pageLoading = false;

          if (res['success']) {
            this.alert = "alert-success";
            this.message = res['message'];

            this.router.navigate(['installer/my-posted-availability-listing']); 
          } else {
            this.alert = "alert-danger";
            this.message = res['message'];
          }
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
    return false;
  }

  onReset() {
    this.formSubmitted = false;
    this.addAppointmentForm.reset();
  }

}
