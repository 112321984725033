import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../_services/home/auth.service';

@Component({
  selector: 'app-home-email-verification',
  templateUrl: './home-email-verification.component.html',
  styleUrls: ['./home-email-verification.component.css'],
  providers: [AuthService]
})
export class HomeEmailVerificationComponent implements OnInit {

  emailVerified = false;
  pageLoading = false;
  message: string;
  alert: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const user_id = +this.route.snapshot.paramMap.get('id');
    this.pageLoading = true;
    this.authService.verifyEmail(user_id)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.emailVerified = true;
          } else {
            this.emailVerified = false;
          }
          this.pageLoading = false;
        },
        error => {
          this.pageLoading = false;
        }
      );
  }
}
