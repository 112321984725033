import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SuccessErrorMessageComponent } from '../_component/success-error-message/success-error-message.component';
import { ListLoaderComponent } from '../_component/list-loader/list-loader.component';
import { JwPaginationComponent } from 'jw-angular-pagination';
import { ChartsModule } from 'ng2-charts';
import { MyPostedAvailabilityComponent } from '../installer/pages/my-posted-availability/my-posted-availability.component';

@NgModule({
  declarations: [SuccessErrorMessageComponent,JwPaginationComponent,ListLoaderComponent, MyPostedAvailabilityComponent],
  imports: [
    CommonModule,
    ChartsModule,
    RouterModule
  ],
  exports: [SuccessErrorMessageComponent,JwPaginationComponent,ListLoaderComponent, MyPostedAvailabilityComponent, ChartsModule]
})
export class SharedModule { } 
