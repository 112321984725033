import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable,throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor(
    private toastr: ToastrService
  ) { }

  // Handle HTTP Errors
  public handleError(error: Response) {
    if (error.status === 400) {
      this.toastr.error('400 Bad Request', 'Client Error');
    } else if (error.status === 403) {
      this.toastr.error('403 Forbidden', 'Client Error');
    } else if (error.status === 404) {
      this.toastr.error('404 Not Found', 'Client Error');
    } else if (error.status === 405) {
      this.toastr.error('405 Wrong Request', 'Client Error');
    } else if (error.status === 500) {
      this.toastr.error('500 Server Error', 'Server Error');
    } else if (error.status === 502) {
      this.toastr.error('502 Bad Gateway', 'Server Error');
    } else if (error.status === 504) {
      this.toastr.error('504 Network Error', 'Server Error');
    } 
     
    return throwError(error);
  }
}
