import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../../_services/admin/admin.service';
import { AuthService } from '../../../_services/home/auth.service';
import { HomeApiService } from '../../../_services/home/home-api.service';

@Component({
  selector: 'app-admin-diponibility',
  templateUrl: './admin-diponibility.component.html',
  styleUrls: ['./admin-diponibility.component.css'],
  providers: [AuthService]
})
export class AdminDiponibilityComponent implements OnInit {

  isEdit = false;
  pageLoading = true;
  items: any;
  itemsGraph: any;
  message: string;
  alertClass: string;
  alert: string;
  loginUser = JSON.parse(localStorage.getItem('appAdmin'));

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private homeApiService: HomeApiService,
    private adminService: AdminService) {

  }

  ngOnInit() {
    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
    this.pageLoading = true;
    this.homeApiService.myAccount(this.loginUser.userToken)
      .subscribe(
        res => {
          this.items = res['data'];
          console.log("Response: ", res);

          if (!res['success']) {
            this.alert = "alert-danger";
            this.message = res['message'];
          }
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
  } 

  onUpdateDiponibility (data) {
    this.items.diponibility = data;
  }

  saveProfile() {
      this.pageLoading = true;
      this.adminService.updateDiponibility(this.items,this.loginUser.userId)
      .subscribe(
        res => {
          console.log("Response: ", res);
          if (res['success']) {
            this.alertClass = "alert-success";
            this.message = res['message'];
          } else {
            this.alertClass = "alert-danger";
            this.message = res['message'];
          }
          this.isEdit = false;
          this.pageLoading = false;
          
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alertClass = "alert-danger";
            this.message = error.error['message'];
          }
          this.isEdit = false;
          this.pageLoading = false;
        }
      );
      
    return false;
  }

}
