import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../_services/home/auth.service';
import { HomeApiService } from '../../../_services/home/home-api.service';

@Component({
  selector: 'app-admin-diponibility',
  templateUrl: './user-diponibility.component.html',
  styleUrls: ['./user-diponibility.component.css'],
  providers: [AuthService]
})
export class UserDiponibilityComponent implements OnInit {

  isEdit = false;
  pageLoading = true;
  items: any;
  itemsGraph: any;
  message: string;
  alertClass: string;
  alert: string;
  loginUser = JSON.parse(localStorage.getItem('appUser'));

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private homeApiService: HomeApiService
    ) {

  }
  ngOnInit() {
    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }
    this.pageLoading = true;
    this.homeApiService.admincommision(this.loginUser.userToken)
      .subscribe(
        res => {
          this.items = res['data'];
          console.log("Response: ", res);

          if (!res['success']) {
            this.alert = "alert-danger";
            this.message = res['message'];
          }
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
  } 


}
