import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute,Router } from '@angular/router';
import { AdminService } from '../../../_services/admin/admin.service';

@Component({
  selector: 'app-admin-user-detail',
  templateUrl: './admin-user-detail.component.html',
  styleUrls: ['./admin-user-detail.component.css']
})
export class AdminUserDetailComponent implements OnInit {

  userId:any;
  pageLoading = true;
  loginUser:any;
  items: any;
  message: string;
  alertClass: string;
  alert:string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminService
  ) {
    this.userId = atob (this.route.snapshot.paramMap.get("userId"));
    
  }

  ngOnInit() {
    this.loginUser = JSON.parse (localStorage.getItem('appAdmin'));

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }

    this.pageLoading = true;
    this.adminService.userDetail(this.loginUser.userToken,this.userId)
      .subscribe(
        res => {
          this.items = res['data'];
          console.log("Response: ", res);

          if (!res['success']) {
            this.alert = "alert-danger";             
            this.message = res['message'];
          } 
          this.pageLoading = false;
        },
        error => {
          console.log("Error: ", error);
          if (error.status === 401) {
            this.alert = "alert-danger";
            this.message = error.error['message'];
          }
          this.pageLoading = false;
        }
      );
  }
  onStatusChange(status,items) {
    if(status == '1'){
      var msg = "Are you sure you want to verify this user.";
    }else{
      var msg = "Are you sure you want to rejected this user.";
    }
   
    if(confirm(msg)){
      const reqData = {};
      reqData['is_user_verify'] = status;
      reqData['userId'] = items.userId;
      items.buttonLoading = true;
      this.adminService.changeuserStatus(reqData)
        .subscribe(
          res => {
            console.log("Response: ", res);
            if (res['success']) {
              this.alert = "alert-success";
              this.message = res['message'];
              items.is_user_verify = status;
            } else {
              this.alert = "alert-danger";             
              this.message = res['message'];
            }
            items.buttonLoading = false;
          },
          error => {
            console.log("Error: ", error);
            if (error.status === 401) {
              this.alert = "alert-danger";
              this.message = error.error['message'];
            }
            items.buttonLoading = false;
          }
        );
    }
    return false;
  }

}