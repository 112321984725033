import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sign-up-link',
  templateUrl: './sign-up-link.component.html',
  styleUrls: ['./sign-up-link.component.css']
})
export class SignUpLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
